import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  NavLink,
} from "react-router-dom";

const ButtonNavigator = (props) => {
  return <div style={{zIndex: 2}} className={"bottom-navigator " + (props.className || "")}>{props.children}</div>;
};

export default ButtonNavigator;
