import * as React from "react";

function PenSvg(props) {
  return (
    <svg
      width={13}
      height={14}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.515.47a1.595 1.595 0 00-2.264.001L6.598 2.125l4.38 4.176 1.553-1.552a1.6 1.6 0 000-2.263L10.515.47zM9.353 7.926L4.973 3.75.862 7.86a2.63 2.63 0 00-.86 1.752L0 12a1 1 0 001 1h2.337a2.632 2.632 0 001.835-.896l4.18-4.18z"
        fill="#FFBF19"
      />
    </svg>
  );
}

export default PenSvg;
