import React, { useState, useEffect, useRef } from "react";
import ArrowUpSvg from "./svg/arrow-up-svg";
import AvatarButton from "./AvatarButton";
import Username from "./Username";
import { Link } from "react-router-dom";
import MenuArrowDown from "./svg/menu-arrow-down-svg";
import HeaderMenuBurgerSvg from "./svg/header-menu-burger-svg";
import Menu from "../pages/Menu";
import avatars from "../model/avatars";
import translate from "../services/Translation/lang";
import ChangeUsernameButton from "./ChangeUsernameButton";
import storage from "../services/Storage/Storage";
import rocketmanTransport from "../services/RocketmanTransport";

function useOuterClick(callback) {
  const innerRef = useRef();
  const callbackRef = useRef();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);

    // read most recent callback and innerRef dom node from refs
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    }
    return () => document.removeEventListener("click", handleClick);
  }, []); // no need for callback + innerRef dep

  return innerRef; // return ref; client can omit `useRef`
}

const HeaderAvatarDesktop = (props) => {
  const [isActive, setActive] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);

  const menuRef = useOuterClick((e) => {
    if (isActive) {
      setActive(false);
    }
    if (isMenuActive) {
      setMenuActive(false);
    }
  });

  const toggleClass = () => {
    setActive(!isActive);
    if (isMenuActive) {
      setMenuActive(false);
    }
  };
  const toggleMenuClass = () => {
    setMenuActive(!isMenuActive);
    if (isActive) {
      setActive(false);
    }
  };

  window.setMenuActive = setMenuActive;

  return (
    <div className="position-relative" ref={menuRef}>
      <div className="d-flex align-items-center">
        <div className={"header-avatar"}>
          <img src={avatars[`avatar${props.avatarId}`]} alt="Avatar" />
        </div>
        <Username className="desktop-username" username={props.username} />
        <div className="ml-2 d-flex align-items-center">
          <button
            className={
              "mr-2 header-item-button " + (isActive ? "active" : "inactive")
            }
            onClick={toggleClass}
          >
            <MenuArrowDown />
          </button>
          <button
            className={
              "header-item-button " + (isMenuActive ? "active" : "inactive")
            }
            onClick={toggleMenuClass}
          >
            <HeaderMenuBurgerSvg />
          </button>
        </div>
      </div>
      {isActive ? (
        <div className="header-avatar-dropdown header-avatar-dropdown-desktop">
          <div className="arrow-up">
            <ArrowUpSvg />
          </div>
          <div className="had-holder">
            <div className="had-avatar-holder">
              <img src={avatars[`avatar${props.avatarId}`]} alt="Avatar" />
            </div>
            <Username username={props.username} />
          </div>
          <Link
            onClick={toggleClass}
            className="menu-buttons"
            to="/user-avatars"
          >
            <AvatarButton title={translate('change_avatar')} />
          </Link>
          {storage.get('showUsernameChangeButton') ? (
            <>
            <div onClick={() => {toggleClass(); window.setShowUserNameDesktop(true)}} >
              <ChangeUsernameButton title={translate('change_username')}  />
            </div>
            </>
          ) : null}
        </div>
      ) : null}
      {isMenuActive ? (
        <div className="header-avatar-dropdown header-avatar-dropdown-menu">
          <div className="arrow-up">
            <ArrowUpSvg />
          </div>
          <Menu />
        </div>
      ) : null}
    </div>
  );
};

export default HeaderAvatarDesktop;
