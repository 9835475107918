import React from "react";
import rocketmanTransport from "../services/RocketmanTransport";

const SecondaryButton = (props) => {
  return (
    <button disabled={props.disabled} className={"secondary-button " + (rocketmanTransport.r7css ? "r7css" : "")} onClick={props.onClick}>
      {props.children}
      {props.title}
    </button>
  );
};

export default SecondaryButton;
