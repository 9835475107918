import * as React from "react";

function FloatingCircleBlueSvg(props) {
  return (
    <svg
      width={49}
      height={49}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter1_d)">
        <circle
          cx={24.5}
          cy={24.5}
          r={4.5}
          fill="url(#prefix__paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint1_linear"
          x1={24.5}
          y1={20}
          x2={24.5}
          y2={29}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3287F2" />
          <stop offset={0.99} stopColor="#3BC4F2" />
        </linearGradient>
        <filter
          id="prefix__filter1_d"
          x={0}
          y={0}
          width={49}
          height={49}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 0.509444 0 0 0 0 0.826782 0 0 0 0 0.933333 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default FloatingCircleBlueSvg;
