import React, { useEffect, useState } from 'react';
import translate from '../../services/Translation/lang';
import moment from 'moment';
import rocketmanTransport from '../../services/RocketmanTransport';

function TournementCounterComponent({ startTime, tournamentInProgress }) {

  const [counter, setCounter] = useState(null);
  useEffect(() => {
    moment.locale('sr');
    let countDown = setInterval(() => {
      const diffTime = moment(startTime).diff(moment().local(), 'milliseconds');
      if (diffTime <= 0) {
        rocketmanTransport.turnamentAnnnouncedisLeaked = true;
        //rocketmanTransport.getTournamentInfoInAbstract();
      } else {
        const start = moment(startTime); // some random moment in time (in ms)
        const end = moment.utc().local() // some random moment after start (in ms)
        const diff = moment(start).diff(moment(end));
        const f = moment.utc(diff).format("HH:mm:ss");
        if (diffTime <= 0) {
          setCounter('00:00:00')
        } else {
          setCounter(f);
        }
      }
    }, 1000);

    return () => clearInterval(countDown);

  }, [counter,startTime]);

  return (
    <div className="tournament-counter-footer">
      {translate('tournament')}
      <div className={"tournament-countdown " + (tournamentInProgress ? "red" : "" )}>
        <img
          src="/svg/tournament/tournament-clock.png"
          alt="tournament-clock"
        />
        {startTime && moment(startTime) > moment(moment.now()).add(1, 'd') ? (
          <span>{moment.duration(moment(startTime).diff(moment.now())).format(`DD[${translate('d')}] HH[${translate('h')}]`)}</span>
        ) : (
          <span>{counter}</span>
        )}
      </div>
    </div>
  )
}

export default TournementCounterComponent;
