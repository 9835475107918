
 // Function to add or update a URL parameter
 export function addOrUpdateUrlParameter(url, paramName, paramValue) {
    // If the URL already contains the parameter, update its value
    if (url.indexOf(paramName + "=") >= 0) {
        var regex = new RegExp("([?&])" + paramName + "=.*?(&|$)");
        return url.replace(regex, '$1' + paramName + "=" + paramValue + '$2');
    } else { // Otherwise, append the parameter to the URL
        if (url.indexOf('?') === -1) {
            return url + '?' + paramName + '=' + paramValue;
        } else {
            return url + '&' + paramName + '=' + paramValue;
        }
    }
}