import React, { useEffect, useState } from "react";
import ButtonNavigator from "../components/main/ButtonNavigator";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import MainTitle from "../components/MainTitle";
import ScrollAnimation from "react-animate-on-scroll";
import HowToPlayStep from "../components/HowToPlayStep";
import DesktopPageBackButton from "../components/DesktopPageBackButton";
import rocketmanTransport from "../services/RocketmanTransport";
import { videoLinks } from "../services/Helpers/VideoHelpLinks";
import YouTube from "react-youtube";
import translate from "../services/Translation/lang";
import DetailedStep from "../components/DetailedStep";
import { useHistory } from "react-router-dom";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default function HowToPlay() {
  const [isPlaying, setIsPlaying] = useState(true);
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);

  const history = useHistory();
  useEffect(() => {
    if (language === 'rs') {
      setLanguage('sr');
    }

    let sound = false;
    let music = false;
    if (isPlaying) {
      if (rocketmanTransport.tempSound) {
        sound = true;
      }
      if (rocketmanTransport.tempMusic) {
        music = true;
      }
    } 
    rocketmanTransport.toggleVideoSoundAndMusic(sound, music);
  },[isPlaying,language])
  const OFFSET = 74;
  return (
    <div className="page-holder with-bottom-navigator how-to-play-page">
      <ScrollToTopOnMount />
      <div className="container">
        <div className="go-to-home" onClick={() => history.replace("/")}></div>
        <div className="desktop-page-holder">
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <div className="desktop-page-back">
              <MainTitle title={translate('how_to_play') + " ? "}/>
              <DesktopPageBackButton />
            </div>
            <p className="mb-3">{translate("folow_the_three_easy_steps")}</p>
            <hr className="desktop-separator-line"></hr>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              duration={2}
              animateIn="fadeIn"
            >
              <div className="video-holder">
              <YouTube
                videoId={videoLinks[language]}
                onPlay={() => setIsPlaying(false)}  
                onPause={() => setIsPlaying(true)}                  
                onEnd={() => setIsPlaying(true)}          
              />
              </div>
            </ScrollAnimation>
          </ScrollAnimation>

         {/*  <div className="steps-holder">
            <ScrollAnimation
              animateOnce
              delay={100}
              offset={OFFSET}
              animateIn="fadeInUp"
            >
              <HowToPlayStep stepNumber={1} helpText={translate("make_a_bet")} />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              delay={200}
              offset={OFFSET}
              animateIn="fadeInUp"
            >
              <HowToPlayStep stepNumber={2} helpText={translate("watch_the_rocket")} />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              delay={300}
              offset={OFFSET}
              animateIn="fadeInUp"
            >
              <HowToPlayStep stepNumber={3} helpText={translate("cashout")} />
            </ScrollAnimation>
          </div> */}
          
          <div className="steps-holder">
            <ScrollAnimation
              animatePreScroll={true}
              animateOnce
              offset={OFFSET}
              delay={400}
              animateIn="fadeInUp"
            >
              <DetailedStep
                stepNumber={1}
                title={translate("make_a_bet")}
                description={translate("input_bet_amount")}
              />
            </ScrollAnimation>
            <ScrollAnimation
              animatePreScroll={false}
              animateOnce
              offset={OFFSET}
              delay={500}
              animateIn="fadeInUp"
            >
              <DetailedStep
                stepNumber={2}
                title={translate("watch_the_rocket")}
                description={translate("watch_take_off")}
              />
            </ScrollAnimation>
            <ScrollAnimation
              animatePreScroll={false}
              animateOnce
              offset={OFFSET}
              delay={600}
              animateIn="fadeInUp"
            >
              <DetailedStep
                stepNumber={3}
                title={translate("cashout")}
                description={translate("cashout_before")}
              />
            </ScrollAnimation>
          </div>
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <p className="big-text-detailed">
              {translate("but_remember_paragraph")}{" "}
              <span>{translate("your_bet_is_lost")}</span>{" "}
            </p>
          </ScrollAnimation>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            duration={2}
            animateIn="fadeIn"
            className="d-none d-lg-flex"
          >
            {
              rocketmanTransport.mobileCloseButton ? (<Link className="menu-buttons" to="/">
              <SecondaryButton title={translate('close')} />
            </Link>) : null
            }
            
            <Link className="menu-buttons detailed-rules-big-button" to="/detailed-rules">
              <PrimaryButton title={translate('detailed_game_rules')} />
            </Link>
          </ScrollAnimation>
        </div>
      </div>
      <ButtonNavigator>
        <div className="container">
          <Row className="gutter-2">
            <Col>
              <Link className="menu-buttons" to="/">
                <SecondaryButton title={translate('close')} />
              </Link>
            </Col>
            <Col>
              <Link className="menu-buttons" to="/detailed-rules">
                <PrimaryButton title={translate('detailed_game_rules')} />
              </Link>
            </Col>
          </Row>
        </div>
      </ButtonNavigator>
    </div>
  );
}
