import React from "react";
import { useRocketmanTransport } from "../rocketman/RocketmanTransportContext";
import translate from "../../services/Translation/lang";

export default function CurrentRoundRetail() {
  const rocketmanContext = useRocketmanTransport();
  const currRound = rocketmanContext.currRound;

  return (
    <p className="slider-text-big">{translate('round')} {currRound} {translate('will_start_soon')}</p>
  );
}
