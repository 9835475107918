import React from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import rocketmanTransport from '../../../services/RocketmanTransport';
import { useRocketmanTransport } from '../../rocketman/RocketmanTransportContext';
import Currency from '../../Currency/Currency';
import JackpotOdometer from '../../JackpotOdometer';

export default function JackpotHolder() {

  const rocketmanContext = useRocketmanTransport();
  const jackpotData = rocketmanContext.jackpotData;
  const { width } = useWindowDimensions();

  const withoutDecimals = () => {
    if (width < 400 || (width >= 1024 && width < 1200) || (rocketmanTransport.amountFormat !== "" && !/\./.test(rocketmanTransport.amountFormat))) {
      return true;
    }
    else return false;
  }

  return (
    <div className="jackpot-cash-holder">
      {rocketmanTransport.retail ? <span style={{ marginBottom: -6}} className='jackpot-title-retail mr-2'>JACKPOT</span> : null}<JackpotOdometer value={Number(Number(jackpotData.jackpot) * Number(rocketmanTransport.myExchangeRate)).toFixed(rocketmanTransport.noOfDecimals)} format={withoutDecimals() ? 0 : 2} duration={60} />  <Currency className={"jackpot-currency " + (rocketmanTransport.r7css ? "r7css" : "")}>{rocketmanTransport.myCurrency}</Currency>
    </div>
  )
}
