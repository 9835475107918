import storage from "../Storage/Storage";

const SHARE_DELAY = 300000; // 10000 equals 10 sec  300 000 5 minutes

var intervalStartedGif = false;
var storageIntervalStarted = storage.get('storageIntervalStartedGif');
var isAbleToShare = storage.get('isAbleToShareGif') === null ? true : storage.get('isAbleToShareGif');
var nextShareCountDown = storage.get('nextShareCountDownGif') || SHARE_DELAY;

const startCanShareInterval = () => {
  storage.set('storageIntervalStartedGif', true);
  var countDownInterval = setInterval(() => {
    nextShareCountDown -= 1000;
    storage.set('nextShareCountDownGif', nextShareCountDown);
    if (nextShareCountDown <= 0) {
      isAbleToShare = true;
      storage.set('isAbleToShareGif', true);
      nextShareCountDown = SHARE_DELAY;
      storage.set('nextShareCountDownGif', nextShareCountDown);
      clearInterval(countDownInterval);
      intervalStartedGif = false;
      storage.set('storageIntervalStartedGif',false);
    }
  }, 1000);
}

export const initializeShareIntervalAfterReload = () => {
  if (storageIntervalStarted) {
    startCanShareInterval();
  }
}

export const canShareGif = () => {
  if (/* !intervalStartedGif ||  */!storage.get('storageIntervalStartedGif')) {
    console.log("DEBUG UI: STARTING INTERVAL")
    startCanShareInterval();
    intervalStartedGif = true;
    storage.set('storageIntervalStartedGif',intervalStartedGif)
  }
  
  if (isAbleToShare) {
    isAbleToShare = false;
    storage.set('isAbleToShareGif', false);
    return true;
  }
  return false;
}
