
export default class AdvancedAutoPlayRoundsHandler {

  constructor() {
    this.roundsPlayed = {
      0: 0,
      1: 0
    };
  }

  /**
   * setRoundsPlayed
   * 
   * @param {number} buttonIndex 
   * @param {number} val 
   */
  setRoundsPlayed(buttonIndex, currRound, ticketRound,  reset = false) {
    if (reset) {
      this.roundsPlayed[buttonIndex] = 0;
    } else {
      if (ticketRound) {
        if (currRound === ticketRound) {
          this.roundsPlayed[buttonIndex] ++;
          try {
            window.setAdvancedAutoPlayRoundsPlayed(buttonIndex,this.roundsPlayed[buttonIndex])
          } catch (error) {
            console.error("Cannot set advanced auto play rounds played for button " + buttonIndex);
          }
        }
      }
    }
    
  }
}
