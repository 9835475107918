import React, { useEffect, useState, useRef, createRef } from "react";
import ChatGifSvg from "../components/svg/chat-gif-svg";
import ChatEmoteSvg from "../components/svg/chat-emote-svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import ScrollAnimation from "react-animate-on-scroll";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import rocketmanTransport from "../services/RocketmanTransport";
import Message from "../components/Chat/Message";
import EmojiPicker from "../components/Chat/Emojis/EmojiPicker";
import useWindowDimensions from "../hooks/useWindowDimensions";
import soundFxPlayer from "../services/Audio/SoundFxPlayer";
import GifsPicker from "../components/Chat/Gifs/GifsPicker";
import translate from "../services/Translation/lang";
import moment from "moment";
import DOMPurify from "dompurify";
import { canShareGif } from "../services/SpamFilter/GifShareSpamFilter";
import { storageEnabled } from "../services/StorageEnabled";
import { filterIsolatedChatMsgs } from "../services/IsolatedMsgs/isolated-msgs";
import MeteorShowerBar from "../components/MeteorShower/MeteorShowerBar";
import { showerMessageHelper } from "../services/Helpers/StringHelper";
import Expire from "../components/main/Expire";
import HeaderDialogWarning from "../components/HeaderDialogWarning";
import HeaderDialogWarningMeteor from "../components/HeaderDialogWarningMeteor";

var sumbitTimeout, gifSubmitTimeout;

export default function Chat(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");

  const OFFSET = 74;
  const [timeLeft, setTimeLeft] = useState(0);
  const [timeLeftSpam, setTimeLeftSpam] = useState(0);
  const defTimeLeft = 3;
  const charLimit = 160;
  const [chat, setChat] = useState([] /* rocketmanTransport.chat */);
  const [textContent, setTextContent] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);
  const [showGifs, setShowGifs] = useState(true);
  const [cursorPosition, setCursorPosition] = useState();
  const [myUsername, setMyUsername] = useState(rocketmanTransport.username);
  const [focused, setFocused] = useState(false);
  const [onlinePlayers, setOnlinePlayers] = useState(1);
  const inputRef = createRef();
  const chatContainer = useRef(null);
  const { width } = useWindowDimensions();
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  const [youAlreadyHaveFreeTickets, setYouAlreadyHaveFreeTickets] =
    useState(false);
  const [showMeteorShowerErrorMessage, setShowMeteorShowerErrorMessage] =
    useState("");

  window.setShowMeteorShowerErrorMessage = setShowMeteorShowerErrorMessage;
  window.setYouAlreadyHaveFreeTickets = setYouAlreadyHaveFreeTickets;

  useEffect(() => {
    window.setChat = (data) => {
      if (rocketmanTransport.updateChat) {
        let arr = [...data];
        setChat(arr);
      }
    };

    window.setOnlinePlayersMobile = setOnlinePlayers;
    window.setChatLang = setLanguage;
    window.setChatUsername = setMyUsername;

    return () => {
      window.setChat = null;
      window.setOnlinePlayersMobile = null;
      window.setChatLang = null;
      window.setChatUsername = null;
    };
  }, []);

  useEffect(() => {
    rocketmanTransport.setChatFocused(focused);
  }, [focused]);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    var intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 0.1);
      if (timeLeft <= 0) {
        setTimeLeft(0);
      }
    }, 100);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  useEffect(() => {
    if (!timeLeftSpam) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalIdSpam = setInterval(() => {
      if (timeLeftSpam <= 0) {
        setTimeLeftSpam(0);
      }
      setTimeLeftSpam(timeLeftSpam - 0.1);
    }, 100);

    return () => clearInterval(intervalIdSpam);
  }, [timeLeftSpam]);

  useEffect(() => {
    inputRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  useEffect(() => {
    rocketmanTransport.updateChat = true;
    if (version !== "desktop") {
      setChat(rocketmanTransport.chat);
    } else {
      if (width < 1024) {
        setChat(rocketmanTransport.chat);
      }
    }
  }, [version, width, props?.desktopActiveChatTab]);

  useEffect(() => {
    if (rocketmanTransport.chatConn) {
      rocketmanTransport.requestTotalOnline();
    }

    return () => (inputRef.current = null);
  }, []);

  useEffect(() => {
    var initialChatUpadeTimeout = setTimeout(() => {
      setChat(rocketmanTransport.chat);
    }, 500);

    return () => clearTimeout(initialChatUpadeTimeout);
  }, []);

  const handleOnChange = (e) => {
    const charLimit = 160;

    soundFxPlayer.play("texting_1");
    if (charLimit - textContent.length <= charLimit) {
      setTextContent(e.target.value.substr(0, charLimit));
    }
  };

  const handleShowEmojis = () => {
    inputRef.current.focus();
    setShowEmojis(!showEmojis);
  };

  const setEmoji = ({ emoji }) => {
    const ref = inputRef.current;
    ref.focus();
    const start = textContent.substring(0, ref.selectionStart);
    const end = textContent.substring(ref.selectionStart);
    const text = start + emoji + end;
    setTextContent(text);
    setCursorPosition(start.length + emoji.length);
  };

  const onFormSubmit = (e) => {
    soundFxPlayer.play("posting");
    e.preventDefault();
    setTimeLeft(defTimeLeft);
    setTextContent("");
    setShowEmojis(false);
    rocketmanTransport.requestSendChatMessage(DOMPurify.sanitize(textContent));
    scrollToBottom();
    sumbitTimeout = setTimeout(function () {
      soundFxPlayer.play("checking_for_spam");
      soundFxPlayer.loop("checking_for_spam", true);
      soundFxPlayer.stop("checking_for_spam");
    }, defTimeLeft * 1000);
  };

  const isUrl = (msg) => {
    const regexGifUrl = msg.match(/.+?(giphy.gif)/);

    if (regexGifUrl) {
      return regexGifUrl[0];
    }

    return false;
  };

  const isShared = (msg) => {
    if (msg.match(/^sharedBet-RCF2408-/)) {
      const sharedMsg = msg.match(/^sharedBet-RCF2408-?(.*)/);
      const filteredShared = sharedMsg[1].replace("*", "666");
      let parsed;
      try {
        parsed = JSON.parse(filteredShared);
      } catch (error) {
        parsed = "";
      }
      return parsed;
    }

    return false;
  };

  const messagesEndRef = useRef(null);
  const gifsRef = useRef(null);
  const emojiRef = useRef(null);
  const formRef = useRef(null);
  const [clickedOutside, setClickedOutside] = useState(false);

  const handleClickOutside = (e) => {
    if (!gifsRef.current.contains(e.target)) {
      setClickedOutside(true);
      setShowGifs(true);
    }
    if (!emojiRef.current.contains(e.target)) {
      setClickedOutside(true);
      setShowEmojis(false);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const scrollToBottom = () => {
    const scroll =
      chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
    chatContainer.current.scrollTo({
      top: scroll,
      behavior: "smooth",
    });
  };

  const submitGif = (selectedGif) => {
    setShowGifs(true);
    setTimeLeft(defTimeLeft);
    if (canShareGif()) {
      rocketmanTransport.requestSendChatMessage(
        DOMPurify.sanitize(selectedGif)
      );
      scrollToBottom();
    }
    gifSubmitTimeout = setTimeout(function () {
      if (canShareGif()) {
        soundFxPlayer.stop("checking_for_spam");
      } else {
        setTimeLeftSpam(defTimeLeft);
        //console.log("SPAM FILTER");
      }
    }, defTimeLeft * 1000);
  };

  useEffect(() => {
    let beginTimeout = setTimeout(() => {
      scrollToBottom();
    }, 500);
    soundFxPlayer.play("chat_appear_2");

    return () => {
      clearTimeout(beginTimeout);
      clearTimeout(gifSubmitTimeout);
      clearTimeout(sumbitTimeout);
    };
  }, [chat.length]);

  const unclaimedMessages = chat.filter(
    (c) =>
      c.msg_lvl === 22 &&
      showerMessageHelper(c.msg).noOfFreeBets >
        Object.keys(c.claims || {}).length
  );

  const adjustTime = (time) => {
    const localTime = moment.utc(time).local();
    if (localTime.isAfter(moment())) {
      return moment();
    }
    return localTime;
  };

  return (
    <div className="page-holder with-bottom-navigator full-height">
      {youAlreadyHaveFreeTickets && (
        <Expire delay="5000" stateChanger={setYouAlreadyHaveFreeTickets}>
          <HeaderDialogWarning
            msg={translate("you_already_have_free_tickets")} className="chat-header-dialog"
          />
        </Expire>
      )}
      {showMeteorShowerErrorMessage ? (
        <Expire delay="5000" stateChanger={setShowMeteorShowerErrorMessage}>
          <HeaderDialogWarningMeteor msg={showMeteorShowerErrorMessage}  className="chat-header-dialog"/>
        </Expire>
      ) : null}

      <div className="chat-page">
        <div className="chat-page-players-holder">
          <div className="chat-page-players-line"></div>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={100}
            duration={1}
            animateIn="fadeInDown"
          >
            <div className="chat-page-players">
              <p>{translate("online")}:</p>
              <span></span>
              <p>{onlinePlayers}</p>
            </div>
          </ScrollAnimation>
        </div>
        <SimpleBar
          scrollableNodeProps={{ ref: chatContainer }}
          className="desktop-chat container chat-messages-holder"
        >
          {/*  <div className=""> */}
          {unclaimedMessages
          .concat(chat.filter((c) => !unclaimedMessages.includes(c)))
          .slice(0)
          .reverse()
          .map((c, index) => {
            return filterIsolatedChatMsgs(c) &&
              "msg_lvl" in c ? (
              <Message
                gif={isUrl(c.msg)}
                sharedBet={isShared(c.msg)}
                key={index}
                id={c.id}
                avatar={`avatar${c.avatar_id}`}
                flagCode={
                  c.country_code &&
                  c.country_code == "XK" &&
                  language === "sr"
                    ? "rs"
                    : c.country_code
                }
                special={"msg_lvl" in c ? c.msg_lvl : -1}
                shower={
                  "msg_lvl" in c ? (c.msg_lvl == 22 ? true : false) : false
                }
                username={c.username}
                entry={c.msg}
                claims={c?.claims}
                numberOfLikes={c.likes}
                myMsg={
                  "msg_lvl" in c && c?.msg_lvl == 22
                    ? c.player_id === rocketmanTransport.player_id
                    : c.username === rocketmanTransport.username
                }
                time={adjustTime(c.time)}
                isVip={c.avatar_id > 49 ? true : false}
              />
            ) : null;
          })}
          <div className="test123" ref={messagesEndRef} />
        </SimpleBar>
        {rocketmanTransport.meteor_shower_active && rocketmanTransport.companyId && Number(rocketmanTransport.companyId) !== 141 ? <MeteorShowerBar /> : null}
        <div className="chat-holder">
          <form
            autoComplete="off"
            onSubmit={onFormSubmit}
            className="chat-page-input-holder"
          >
            <input
              ref={inputRef}
              className="chat-page-input"
              name="content"
              value={textContent}
              onChange={handleOnChange}
              placeholder={translate("write_a_replay")}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <p className="char-counter-holder">
              {charLimit - textContent.length <= 0 ? (
                <span className="counter-zero">0</span>
              ) : (
                <span>{charLimit - textContent.length}</span>
              )}
            </p>
            <button
              disabled={timeLeft > 0 ? true : false}
              className="d-none"
              type="submit"
            >
              Submit
            </button>
          </form>
          <div ref={gifsRef} onClick={handleClickInside}>
            {!showGifs ? <GifsPicker submitGif={submitGif} /> : null}
            <button
              onClick={() => setShowGifs(!showGifs)}
              className="chat-button mr-3"
            >
              <ChatGifSvg />
            </button>
          </div>
          <div ref={emojiRef} onClick={handleClickInside}>
            <div
              className={["emoji-container", !showEmojis && "hide-emoji"].join(
                " "
              )}
            >
              {storageEnabled() ? <EmojiPicker setEmoji={setEmoji} /> : null}
            </div>
            <button className="chat-button" onClick={handleShowEmojis}>
              <ChatEmoteSvg />
            </button>
          </div>
          <div className={"chat-spam-filter " + (timeLeft > 0 ? "active" : "")}>
            <p>
              {translate("checking_for_spam")}{" "}
              <span>
                {timeLeft.toFixed(0)}
                {translate("seconds_left")}
              </span>
            </p>
            <ProgressBar now={(100 / defTimeLeft) * timeLeft - 10} />
          </div>
          <div
            className={"chat-spam-filter " + (timeLeftSpam > 0 ? "active" : "")}
          >
            <p>{translate("cant_post_media_content")}</p>
          </div>
        </div>
      </div>
      {/* <BottomTabNavigator /> */}
    </div>
  );
}
