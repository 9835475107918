import React from "react";
import UserMessage from "../UserMessage";
import CurrentUserMessage from "../CurrentUserMessage";

const ROCKETMAN_USERNAME = "ROCKETMAN";

export default function Message({
  myMsg,
  entry,
  avatar,
  flagCode,
  numberOfLikes,
  username,
  time,
  id,
  gif,
  sharedBet,
  isVip,
  special,
  shower,
  claims,
  claimed
}) {
  if (myMsg) {
    return (
      <CurrentUserMessage
        entry={entry}
        time={time}
        id={id}
        sharedBet={sharedBet}
        numberOfLikes={numberOfLikes}
        username={username}
        gif={gif}
        shower={shower}
        claims={claims}
      />
    );
  } else {
    return (
      <UserMessage
        shower={shower}
        special={special}
        gif={gif}
        id={id}
        sharedBet={sharedBet}
        avatar={avatar}
        flagCode={flagCode}
        username={username}
        entry={entry}
        numberOfLikes={numberOfLikes}
        time={time}
        isVip={isVip}
        claims={claims}
      />
    );
  }
}
