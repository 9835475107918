export default function mpHexColorHelper(mp) {
    if (mp >= 100) {
      return 0xdb1f35; // Red
    } else if (mp >= 50) {
      return 0xffbf00; // Orange
    } else if (mp >= 10) {
      return 0xf7fd04; // Yellow
    } else {
      return 0xFFFFFF; // White
    }
  }
  