import React from "react";
import translate from "../../services/Translation/lang";
import ElbetLogoLoader from "../svg/elbet-logo-loader";
import ElbetLogoLoaderLower from "../svg/elbet-logo-loader-lower";

const SessionDisconnected = ({gameDissconnected}) => {

  return (
    <div className={["loading-screen", gameDissconnected ? "" : "hide"].join(" ")} >
      <ElbetLogoLoader />
      <p className="loading-screen-text">{translate('multisession_not_allowed')}</p>
      <div className="loading-screen-footer">
        From
        <ElbetLogoLoaderLower />
      </div>
    </div>
  );
};

export default SessionDisconnected;
