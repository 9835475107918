import React from 'react'
import { useRocketmanTransport } from '../rocketman/RocketmanTransportContext';
import translate from '../../services/Translation/lang';

export default function RetailRoundId() {
    const rocketmanContext = useRocketmanTransport();
    const currRound = rocketmanContext.currRound;

  return (
    <div className="retail-round-id">
        <span className='retail-round-id-title'>{translate('round_id')}</span>
        <span>{currRound}</span>
    </div>
  )
}
