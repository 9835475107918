import React from 'react';
import translate from '../../services/Translation/lang';
import { currencyFormatter } from '../../services/Helpers/NumberFormatter';
import rocketmanTransport from '../../services/RocketmanTransport';
import Currency from '../Currency/Currency';

export default function OhSnapTextInsurance({insuranceSnapped}) {

  //const rocketmanContext = useRocketmanTransport();
  

  return (
    <div className="oh-snap-pop-up-body">
      <div className="oh-snap-pop-up-box">
        <h5>{translate('your_insurance_returns_you')} {currencyFormatter(insuranceSnapped)}&nbsp;
          <Currency>{rocketmanTransport.myCurrency}</Currency></h5>
      </div>
    </div>
  )
}
