import React, { useState } from "react";
import AstronautPromoCreditImage from "../../img/astronaut-promo-credit.png";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import PrimaryButton from "../PrimaryButton";
import Currency from "../Currency/Currency";
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";

const AstronautPromoCredit = ({ show, setShow, bonusCredit, promoText }) => {
  const [astroPromoCredit, setAstroPromoCredit] = useState(0);

  window.setAstroPromoCredit = setAstroPromoCredit;

  if (!show) return null;

  return (
    <div className="pop-up astronaut-popup blured-popup new-popup">
      <div className="astronaut-pop-up-body new-popup-body blue-text-body">
        <img
          className="new-popup-img"
          src={AstronautPromoCreditImage}
          alt="astronaut-promo-credit"
        />
        <div className="new-popup-wrapper">
          <div className="pop-up-promo-text">
            <div className="pop-up-promo-text-holder">
              <h1 className="pupt-vissible">{promoText}</h1>
              <h1 className="pupt-hidden">{promoText}</h1>
            </div>
          </div>
          <div className="pop-up-message-box">
            <div className="pop-up-promo-bonus">
              <div className="pop-up-promo-bonus-holder">
                <h1 className="pupb-vissible">
                  +{currencyFormatter(astroPromoCredit)}
                  <Currency>{rocketmanTransport.myCurrency}</Currency>
                </h1>
                <h1 className="pupb-hidden">
                  +{currencyFormatter(astroPromoCredit)}
                  <Currency>{rocketmanTransport.myCurrency}</Currency>
                </h1>
              </div>
            </div>
            <p className="pop-up-message-description">
              {translate("promo_credit_condradulations")}
            </p>
          </div>
          <div className="pop-up-close new-pop-up-close">
            <PrimaryButton
              title={translate("ok_thanks")}
              onClick={() => setShow(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AstronautPromoCredit;
