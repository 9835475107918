import { useEffect, useState } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import ArrowUpPlayNowSvg from "../svg/arrow-up-play-now-svg";
import TournamentLeaderboardTr from "../TournamentLeaderboardTr";

const TournamentLeaderboard = () => {
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  const [show, setShow] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [tournamentStatus, setTournamentStatus] = useState(0)
  const [mounted, setMounted] = useState(false);

  window.setLeaderboardTournamentStatus = setTournamentStatus;

  useEffect(() => {
    if (document.getElementById("mainGameId") && tournamentStatus == 3) {
      document.getElementById("mainGameId").classList.add("canvas-tournament-active");
    } else {
      document.getElementById("mainGameId").classList.remove("canvas-tournament-active");
    }
  },[tournamentStatus]);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
      try {
        document.getElementById("mainGameId").classList.remove("canvas-tournament-active");
      } catch (error) {
        console.log("error removing classList canvas-tournament-active"); 
      }
    }
  },[]);

  if (mounted) {}
  window.setLeaderboard = (data) => {
    if (mounted) {
      setLeaderboard(data);
    }
  }
  return (
    leaderboard.length > 0 &&
    <div className={"tournament-leaderboard-holder " + (rocketmanTransport.launcherHideHeader == 1 ? " no-header" : "")}>
      <div className={"tournament-leaderboard " + (show ? "expanded" : "")}>
        {leaderboard.map((player, index) => {
          return (
            <TournamentLeaderboardTr
              key={index}
              rank={Number(player.Place)}
              points={Number(player.Points)}
              avatar={`avatar${player.AvatarID}`}
              username={player.UserName}
              flagCode={
                player.CountryCode == "XK" && language === "sr"
                  ? "rs"
                  : player.CountryCode
              }
              isVip={player.AvatarID > 49 ? true : false}
            />
          );
        })}
      </div>
      <button
        onClick={() => setShow(!show)}
        className={"tournament-toggle " + (show ? "active" : "")}
      >
        <ArrowUpPlayNowSvg />
      </button>
    </div>
  );
};

export default TournamentLeaderboard;
