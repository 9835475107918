import React, { useState } from "react";

const RetailFooter = () => {
  const [multipliersHistory, setMultiplierHistory] = useState([]);
  window.setRetailMultipliersHistory = setMultiplierHistory;

  let highLight = (mp) => {
      if (Number(mp) > 0.99 && Number(mp) <= 1.99) return "light-blue";
      if (Number(mp) > 1.99 && Number(mp) <= 4.99) return "blue";
      if (Number(mp) > 4.99 && Number(mp) <= 9.99) return "green";
      if (Number(mp) > 9.99 && Number(mp) <= 49.99) return "yellow";
      if (Number(mp) > 49.99 && Number(mp) <= 99.99) return "orange";
      if (Number(mp) > 99.99) return "red";

      return "win-small";
    };

  return (
    <div
      className={
        "play-now-footer play-now-footer-retail"
      }
    >
      <div className="container">
        <div className="play-now-footer-mmsb-holder">
          {multipliersHistory
            .slice(0)
            .reverse()
            .map((mp, index) => {
              return (
                <p
                  className={["mmsb-info-content", highLight(mp)].join(" ")}
                  key={index}
                >
                  {mp ? mp.toFixed(2) : ""}x
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RetailFooter;
