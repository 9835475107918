import moment from "moment";
import React, { useEffect, useState } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import BiggestOddsItem from "../BiggestOddsItem";

const BiggestOddsTab = ({timeFilter}) => {

  const [bets, setBets] = useState([]);

  useEffect(() => {
    switch (timeFilter) {
      case translate('week'):
        rocketmanTransport.requestBiggestOddsWeekly();
        break;
      case translate('month'):
        rocketmanTransport.requestBiggestOddsMonthly();
        break;
      case translate('year'):
        rocketmanTransport.requestBiggestOddsYearly();
        break;
      default:
        break;
    }
  },[timeFilter]);

  window.setBiggestOdds = setBets;

  return (
    <div>
       {bets.map((bet,index) => {
        return (
          <BiggestOddsItem key={index} cashedOut={Number(bet.mp).toFixed(2)} roundId={bet.round_id} betTime={moment.utc(bet.round_open).local().format("DD.MM.  HH:mm")}/>
        )
       })}
    </div>
  );
};

export default BiggestOddsTab;
