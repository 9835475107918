import moment from "moment";
import React, { useState } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import TournamentPrevTabBox from "./TournamentPrevTabBox";

const TournamentPrevTab = (props) => {
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);

  window.setAllBetsTabLang = setLanguage;
  
  return (
    <>
      {rocketmanTransport.tournamentHistory.sort((a,b) => moment(b.EndTime) - moment(a.EndTime)).map((tournament, index) => {
        return (
          <TournamentPrevTabBox
            key={index}
            name={tournament.TournamentName}
            tournamentId={tournament.ID}
            date={tournament.EndTime}
            tournamentCurrency={tournament.CurrencyID}
          />
        );
      })}
    </>
  );
};

export default TournamentPrevTab;
