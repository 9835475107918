import React from 'react'
import rocketmanTransport from '../../../services/RocketmanTransport'
import { currencyFormatter } from '../../../services/Helpers/NumberFormatter'

export default function RetailBetCol(props) {
  return (
    <>
    {props.bet < 0.01
          ? "<0.01"
          : props.bet % 1 !== 0
          ? currencyFormatter(Number(props.bet).toFixed(rocketmanTransport.noOfDecimals))
          : currencyFormatter(Number(props.bet))}{" "}
    </>
  )
}
