import * as React from "react"

function FloatingCirclePurpleSvg(props) {
  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter2_d)">
        <circle cx={15} cy={15} r={5} fill="url(#prefix__paint2_linear)" />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint2_linear"
          x1={15}
          y1={10}
          x2={22.778}
          y2={22.222}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A8FF" />
          <stop offset={0.99} stopColor="#633AFA" />
        </linearGradient>
        <filter
          id="prefix__filter2_d"
          x={0}
          y={0}
          width={30}
          height={30}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.77 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default FloatingCirclePurpleSvg
