import React from "react";
import translate from "../services/Translation/lang";
import rocketmanTransport from "../services/RocketmanTransport";

const AddAnotherBet = (props) => {
  
  return (
    <button
      disabled={props.disabled}
      className={"primary-button " + (props.isActive ? "button-remove-bet " : "") + (rocketmanTransport.r7css ? "r7css" : "")}
      onClick={props.onClick}
    >
      <div className={"circle-plus closed mr-2 " + (props.isActive ? "opened" : "")}>
        <div className="circle">
          <div className="horizontal"></div>
          <div className="vertical"></div>
        </div>
      </div>
      {!props.isActive ? translate('add_another_bet') : translate('remove_additional_bet')}
    </button>
  );
};

export default AddAnotherBet;
