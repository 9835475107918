import React, { useState, useEffect } from "react";
import CurrentRoundRetail from "./CurrentRoundRetail";
import translate from "../../services/Translation/lang";
//import rocketmanTransport from "../../services/RocketmanTransport";

export default function CountdownSlider() {
  const [count, setCount] = useState(10);
  const [pulseKey, setPulseKey] = useState(0);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
        setPulseKey(prevKey => prevKey + 1);
      } else {
        clearInterval(countdownInterval);
      }
    }, 1000);

    // if (count < 2) {
    //   rocketmanTransport.setMainShowScreen('READY_TO_FLY');
    // }

    return () => clearInterval(countdownInterval); 
  }, [count]); 

  return (
    <div className="slide countdown-slider">
      <CurrentRoundRetail />
      <div
        key={pulseKey}
        className={"countdown-counter " + (count < 6 ? "pulse-strong" : "")}
        style={{
          // animation: 'pulse 600ms linear',
          color: count < 6 ? '#db1f35' : 'white'
        }}
      >
        {count < 10 ? `0${count}` : count}
      </div>
      <p className="slider-text-big">{translate('hurry_up_and_place_your_bets')}</p>
    </div>
  );
}
