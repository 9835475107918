import React, { useEffect, useRef, useState} from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import MyBetsTable from "../Bets/MyBetsTable";
import translate from "../../services/Translation/lang";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Sticky from "react-sticky-el";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import BonusingHistoryTable from "../Bets/BonusingHistoryTable";
import { useParams } from "react-router-dom";

const MyBetsTab = () => {
  const [myBetsList, setMyBetsList] = useState(rocketmanTransport.myBetsList);
  
  const { historyTab } = useParams();

  const [tabIndex, setTabIndex] = useState(Number(historyTab) || rocketmanTransport.historyTabIndex);

  window.setHistoryTab = setTabIndex;

  const myCurrency = rocketmanTransport.myCurrency;


  const isMounted = useRef(false);

  useEffect(() => {
    if(isMounted.current === false) {
      setMyBetsList(rocketmanTransport.myBetsList);
      isMounted.current = true;
    }

    return () => isMounted.current = false;
  },[]);

  window.setMyBetsList = (data) => {
    if (isMounted.current) setMyBetsList(data);
  }

  return (
    <Tabs
      selectedTabClassName="selected-pod-tab"
      selectedIndex={tabIndex}
      onSelect={(index) => {
        setTabIndex(index); 
        soundFxPlayer.play("slider");
      }}
    >
      <Sticky
        topOffset={-74}
        className="sticky-header-holder"
        stickyClassName="sticky-header"
      >
        <header>
          <TabList className="pod-tabs-nav">
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("my_bets")}</Tab>
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>
              {translate("bonusing_history")}
            </Tab>
          </TabList>
        </header>
      </Sticky>

      <div className="pod-tabs-panel-holder">
        <TabPanel>
          <div className="container">
            <MyBetsTable myBets={myBetsList} myCurrency={myCurrency} />
          </div>
        </TabPanel>
        <div className="container">
        <TabPanel>
          <BonusingHistoryTable />
        </TabPanel>
        </div>
      </div>
    </Tabs>
  );
};

export default MyBetsTab;
