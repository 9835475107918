import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "animate.css/animate.compat.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Moment from "react-moment";
import { addOrUpdateUrlParameter } from "./services/Helpers/UrlHelper";
import { urlParams } from "./services/RocketmanTransport";

Moment.startPooledTimer();

if (urlParams.get('region') === 'retail') {
  if (urlParams.get('version') !== 'desktop') {
    window.location.href =  addOrUpdateUrlParameter(window.location.href,'version', 'desktop')
  } 
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
