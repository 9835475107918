import React from "react";
import translate from "../../services/Translation/lang";
import ElbetLogoLoader from "../svg/elbet-logo-loader";
import ElbetLogoLoaderLower from "../svg/elbet-logo-loader-lower";

const LostConnection = ({lostConnection}) => {

  return (
    <div className={["loading-screen", lostConnection ? "" : "hide"].join(" ")} >
      <ElbetLogoLoader />
      <p className="loading-screen-text">{translate('lost_connection')} <span className="dot-pulse"></span></p>
      <div className="loading-screen-footer">
        From
        <ElbetLogoLoaderLower />
      </div>
    </div>
  );
};

export default LostConnection;
