import React from "react";
import ArrowRightSvg from "./svg/arrow-right-svg";
import { useHistory } from "react-router-dom";
import translate from "../services/Translation/lang";

const DesktopPageBackButton = (props) => {
  const history = useHistory();
  return (
    <button
      disabled={props.disabled}
      className="desktop-page-back-button"
      onClick={() => history.replace("/")}
    >
      {translate('go_back')}
      <ArrowRightSvg />
    </button>
  );
};

export default DesktopPageBackButton;
