

export default function randomElemGenerator() {
  const elems = [
    'div',
    'span',
    'a',
    'button'
  ];

  const randomIndex = Math.floor(Math.random() * elems.length);

  return elems[randomIndex];
}
