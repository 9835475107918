import React, { useState, useEffect } from "react";
import generateRandomInteger from "../services/Helpers/RandNumbersRange";
import FloatingCoinFirstSvg from "./svg/floating-coins/floating-coin-first-svg";
import { useSpring, animated } from 'react-spring'

const animationSpeed = generateRandomInteger(1200, 1800);

function FloatingCoin({ delay }) {

  const [speed, setSpeed] = useState(0);
  const [coinX, setCoinX] = useState(generateRandomInteger(0,300));
  const [coinY, setCoinY] = useState(0);

  const rainAimation = useSpring({  
    delay,
    loop: true,
    from: { y: -200 /* generateRandomInteger(-150,-450) */, x: coinX},
    to: { y: window.screen.height, x: coinX - generateRandomInteger(30,60) },
    config: {duration: animationSpeed},
  });

  const coinAnimation = useSpring({  
    loop: true,
    from: { rotateZ: generateRandomInteger(0,180),  scale: 1, rotateX:generateRandomInteger(0,60) },
    to: { rotateZ: generateRandomInteger(181,360),  scale: 1.5, rotateX:generateRandomInteger(250,360)},  
    config: { duration: generateRandomInteger(800,1200)},
  });

  return (
    <animated.div className={"floating-coin "} style={{ left:coinX + 'px' , /* top: coinY + 'px' , */ ...rainAimation}}>
      {<FloatingCoinFirstSvg style={coinAnimation} />}
    </animated.div>
  );
}

export default FloatingCoin;
