import React, { useState, useEffect } from "react";
import translate from "../../services/Translation/lang";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
const screenfull = require("screenfull");

const FullscreenMode = () => {
  const [fullscreenMode, setFullscreenMode] = useState(false);

  useEffect(() => {
    if (screenfull.isEnabled) {
      // toggleFullScreen()
      screenfull.on("change", () => {
        if (screenfull.isFullscreen ) {
          document.querySelector(".fullscreen-clock").classList.add("show");
        } else {
          document.querySelector(".fullscreen-clock").classList.remove("show");
        }
      });
    } else {
      setFullscreenMode(true)
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change");
      }
    }
  }, []);
  const toggleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
      setFullscreenMode(!fullscreenMode);
    }
  };
  return (
    <div className={"fullscreen-mode " + (fullscreenMode ? "hide" : "")}>
      <h3>
        {translate('fullscreen_mode')}
      </h3>
      <div className="pop-up-close">
        <PrimaryButton title={translate('allow')} onClick={toggleFullScreen} />
        <SecondaryButton
          title={translate('deny')}
          onClick={() => setFullscreenMode(!fullscreenMode)}
        />
      </div>
    </div>
  );
};

export default FullscreenMode;
