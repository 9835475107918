import React from "react";

function ChatGifSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      fill="none"
      viewBox="0 0 24 22"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M22 2H2v18h20V2zM2 0a2 2 0 00-2 2v18a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2H2z"
        clipRule="evenodd"
        opacity="1"
      ></path>
      <g fill="#fff" opacity="1">
        <path d="M7.525 12.061v-1.355h2.237v3.988c-1.077.173-1.95.26-2.621.26-1.198 0-2.023-.32-2.474-.96-.445-.64-.667-1.658-.667-3.051 0-1.393.233-2.399.7-3.017C5.167 7.31 5.962 7 7.084 7c.7 0 1.458.08 2.271.237l.407.08-.045 1.208a21.462 21.462 0 00-2.226-.147c-.572 0-.975.068-1.209.204-.233.135-.407.388-.52.757-.105.361-.158.986-.158 1.875 0 .881.11 1.499.328 1.853.218.354.67.53 1.356.53l.937-.044V12.06h-.7zM11.234 14.818V7.136h1.56v7.682h-1.56zM14.467 14.818V7.136h4.903V8.49h-3.344v2.26h2.734v1.356h-2.734v2.711h-1.56z"></path>
      </g>
    </svg>
  );
}

export default ChatGifSvg;
