import React, { useEffect, useState } from "react";
import BottomTabNavigator from "../components/main/BottomTabNavigator";
import MenuItem from "../components/MenuItem";
import MenuItemSwitch from "../components/MenuItemSwitch";
import PrimaryButton from "../components/PrimaryButton";
import ArrowLeftButtonSvg from "../components/svg/arrow-left-button-svg";
import HowToPlaySvg from "../components/svg/how-to-play-svg";
import ProvablyFairFooter from "../components/ProvablyFairFooter";
import { Link } from "react-router-dom";
import rocketmanTransport from "../services/RocketmanTransport";
import translate from "../services/Translation/lang";
import useWindowDimensions from "../hooks/useWindowDimensions";
// import { useHistory } from "react-router-dom";

export default function Menu() {
  // const history = useHistory();
  const [sound, setSound] = useState(rocketmanTransport.sound);
  const [music, setMusic] = useState(rocketmanTransport.music);
  const [animation, setAnimationMenu] = useState(rocketmanTransport.animation);
  const [backUrl, setBackUrl] = useState("");

  const toggleSound = (val) => {
    rocketmanTransport.toggleSound(val);
    setSound(rocketmanTransport.sound);
  };

  const toggleMusic = (val) => {
    rocketmanTransport.toggleMusic(val);
    setMusic(rocketmanTransport.music);
  };

  const toggleAnimation = (val) => {
    rocketmanTransport.toggleAnimation(val);
  };

  window.setAnimationMenu = setAnimationMenu;
  const { width } = useWindowDimensions();

  const betSwitch = () => {
    if (width >= 1024 ) {
      rocketmanTransport.setHistoryTabIndex(0);
      window.setTabIndex(1);
      if (window.setHistoryTab) {
        window.setHistoryTab(0);
      }
      return "/";
    }
    return "/bets/1";
  };

  const betHistorySwitch = () => {
    if (width >= 1024) {
      rocketmanTransport.setHistoryTabIndex(1);
      window.setTabIndex(1);
      if (window.setHistoryTab) {
        window.setHistoryTab(1);
      }
      return "/";
    }
    return "/bets/1/1";
  };

  // adding the protocol if it's missing
  useEffect(() => {
    const url = rocketmanTransport.launcherBackUrl;
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      const correctedUrl = "https://" + url; 
      setBackUrl(correctedUrl);
    } else {
      setBackUrl(url);
    }
  }, [rocketmanTransport.launcherBackUrl]);

  return (
    <div className="full-height">
      <div className="page-holder with-provably with-bottom-navigator">
        <div className="container menu-page">
          <div className="animated duration-1 fadeInUp">
            <MenuItemSwitch
              checked={sound}
              image="soundSettings"
              title={translate("sound_settigs")}
              toggle={toggleSound}
            />
          </div>
          <div className="animated duration-2 fadeInUp">
            <MenuItemSwitch
              checked={music}
              image="musicSettings"
              title={translate("music_settings")}
              toggle={toggleMusic}
            />
          </div>
          <div className="animated duration-3 fadeInUp">
            <MenuItemSwitch
              checked={animation}
              image="musicSettings"
              title={translate("toggle_animation")}
              toggle={toggleAnimation}
            />
          </div>
          <div className="animated duration-4 fadeInUp">
            <MenuItem
              image="gameRules"
              title={translate("game_rules")}
              linkTo="/detailed-rules"
            />
          </div>
          <div className="animated duration-5 fadeInUp">
            {width >= 1024 ? (
              <div onClick={() => betSwitch()}>
                <MenuItem
                  image="betHistory"
                  title={translate("my_bet_history")}
                  linkTo={""}
                />
              </div>
            ) : (
              <MenuItem
                image="betHistory"
                title={translate("my_bet_history")}
                linkTo="/bets/1"
              />
            )}
          </div>
          <div className="animated duration-6 fadeInUp">
            {width >= 1024 ? (
              <div onClick={() => betHistorySwitch()}>
                <MenuItem
                  image="betHistory"
                  title={translate('my_bonysing_history')}
                  linkTo={""}
                />
              </div>
            ) : (
              <MenuItem
                image="betHistory"
                title={translate('my_bonysing_history')}
                linkTo="/bets/1/1"
              />
            )}
          </div>
        </div>
        <div>
          <div className="container">
            <div className="menu-buttons-holder">
            {backUrl && backUrl != null ?
                (<div className="menu-buttons">
                <PrimaryButton title={translate("back_to_home")} onClick={() => backUrl ? 
                  window.top.location.replace(backUrl) : rocketmanTransport.localErrors("Back Url Not Set")}>
                  <ArrowLeftButtonSvg className="mr-2" />
                </PrimaryButton> 
              </div>) : null
              }
              {/* {backUrl && backUrl != null ? (
                <div className="menu-buttons">
                  <PrimaryButton
                    title={translate("back_to_home")}
                    onClick={() => {
                      window.setMenuActive(false);
                      history.replace("/");
                    }}
                  >
                    <ArrowLeftButtonSvg className="mr-2" />
                  </PrimaryButton>
                </div>
              ) : null} */}
              <Link
                className="menu-buttons"
                to="/how-to-play"
                onClick={() => window.setMenuActive(false)}
              >
                <PrimaryButton title={translate("how_to_play")}>
                  <HowToPlaySvg className="mr-2" />
                </PrimaryButton>
              </Link>
            </div>
          </div>
          <ProvablyFairFooter />
        </div>
      </div>
    </div>
  );
}
