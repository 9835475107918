import React from "react";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";
import translate from "../services/Translation/lang";
import ProvablyFair from "./svg/provably-fair-svg";
import rocketmanTransport from "../services/RocketmanTransport";

const BiggestOddsItem = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");
  const history = useHistory();
  const { width } = useWindowDimensions();

  const handleBetVerify = (roundId) => {
    if (version !== "desktop" || width < 1024) {
      history.replace({
        search: `?ticketRid=${roundId}&ticketTime=${props.betTime}`,
        pathname: '/bet-verify',
      });
    } else {
      window.setBetVerifyActive(true);
      window.setBetInfoActive(false);
      window.setTicketRid(roundId);
      window.setTicketTime(props.betTime);
    }
  };

  const highLight = (mp) => {
    if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
    if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
    if (Number(mp) > 99.99) return "win-legendary";

    return "win-small";
  }

  return (
    <div className="biggest-odds-item">
      <div className="biggest-odds-info">
      <p className={["mmsb-info-content", highLight(props.cashedOut)].join(" ")}>{props.cashedOut}x</p>
      </div>
      <div className="biggest-odds-info">
        <div className="mmsb-info">
          <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('round_id')}</p>
          <p className="mmsb-info-content">{props.roundId}</p>
        </div>
      </div>
      <div className="biggest-odds-info">
        <div className="mmsb-info">
          <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('bet_time')}</p>
          <p className="mmsb-info-content">{props.betTime}</p>
        </div>
      </div>
      <div className="biggest-odds-info">
        <button className="table-share" onClick={() => handleBetVerify(props.roundId)}>
          <ProvablyFair width={14} height={17} />
        </button>
      </div>
    </div>
  );
};

export default BiggestOddsItem;
