import React, { useState } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import TournamentPrevTr from "../TournamentPrevTr";
import moment from "moment";

export default function TournamentPrevTabBox(props) {
  // const PAGE_LENGTH = 50;
  // const [listLength, setListLength] = useState(50);
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  const [show, setShow] = useState(false);
  const [tournamentIdHistories, setTournamentIdHistories] = useState([]);

  window.setAllBetsTabLang = setLanguage;
  window.setTournamentPersonalizedResultsHistory = setTournamentIdHistories;

  const fillWithtournamentData = () => {
    if (!show) {
      rocketmanTransport.getPersonalizedTournamentResultsHistory(props.tournamentId);
      setShow(true)
    } else {
      setShow(false);
    }
  }

  return (
    <>
      <div className="tournament-accordion-item">
        <div
          onClick={() => fillWithtournamentData()}
          className={"tournament-accordion-header " + (show ? "show" : "")}
        >
          <span className="tournament-accordion-header-name">{props.name}</span>
          <span className="tournament-accordion-header-date">{moment(new Date(props.date)).format("DD/MM/YYYY")}</span>
        </div>
        {show ? (
          <div className="tournament-accordion-body">
            <table className="bets-table tournament-table">
              <thead>
                <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
                  <th>{translate("rank")}</th>
                  <th>{translate("user_and_points")}</th>
                  <th>{translate("prize")}</th>
                </tr>
              </thead>
              <tbody>
                {tournamentIdHistories.map((player, index) => {
                  return (
                    <TournamentPrevTr
                      key={index}
                      rank={Number(player.Place)}
                      points={Number(player.Points)}
                      avatar={`avatar${player.AvatarID}`}
                      username={player.UserName}
                      prize={Number(player.PlaceAward)}
                      currency={props.tournamentCurrency}
                      flagCode={
                        player.CountryCode == "XK" && language === "sr"
                          ? "rs"
                          : player.CountryCode
                      }
                      isVip={player.AvatarID > 49 ? true : false}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </>
  );
}
