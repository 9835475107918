import React from 'react';
//import { currencyFormatter } from '../services/Helpers/NumberFormatter';
import rocketmanTransport from '../services/RocketmanTransport';
import Currency from './Currency/Currency';

export default function WinTableCol(props) {
  return (
    <td>
      {props.win ? (
        <>
          <span className="ml-1">
            {props.win < 0.01
              ? "<0.01" : 
              // : props.win % 1 !== 0
              // // ? currencyFormatter(Number(props.win).toFixed(rocketmanTransport.noOfDecimals))
              // // : currencyFormatter(Number(props.win))}
              // ? Number(props.win).toFixed(rocketmanTransport.noOfDecimals)
              //  : Number(props.win)}
              Number(props.win).toFixed(rocketmanTransport.noOfDecimals)}
          </span>{" "}
          <Currency>{props?.valute}</Currency>
        </>
      ) : (
        <span> --- </span>
      )}
    </td>
  );
}
