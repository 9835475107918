import * as React from "react";

function PlayNowSvg(props) {
  return (
    <svg
      width={18}
      height={21}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 19h4a2 2 0 002 2h6a2 2 0 002-2h4v-5.5l-4-3V7c0-3.156-1.914-7-5-7-3.086 0-5 3.844-5 7v3.5l-4 3V19zm9-9a2 2 0 110-4 2 2 0 010 4z"
        fill="#fff"
      />
    </svg>
  );
}

export default PlayNowSvg;
