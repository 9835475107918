import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import LoadingNextRound from "../BetComponent/LoadingNextRound";
import OhSnapText from "../BetComponent/OhSnapText";
import HighScoreTable from "../HighScoreTable/HighScoreTable";
import OhSnapTextInsurance from "../BetComponent/OhSnapTextInsurance";

const OhSnap = ({ show, insuranceSnapped = false }) => {
  const location = useLocation();
  const showModalIfOnRoute = useRef(true);
  const popUpRef = useRef(null);
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (location.pathname !== "/") {
      showModalIfOnRoute.current = false;
    } else {
      showModalIfOnRoute.current = true;
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        window.setRocketSnapped(false);
        setIsVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  useEffect(() => {
    if (show) {
      soundFxPlayer.volume('screen_transition_1', 0.05);
      soundFxPlayer.play('screen_transition_1');
    } else {
      soundFxPlayer.volume('screen_transition_outro', 0.05);
      soundFxPlayer.play('screen_transition_outro');
    }
  }, [show]);

  useEffect(() => {
    setIsVisible(show);
  }, [show]);

  return (
    <div onClick={() => window.setRocketSnapped(false)} >
      {isVisible && showModalIfOnRoute.current ? (
        <div className="pop-up on-snap-pop-up" ref={popUpRef}>
          <div>
            <OhSnapText />
            {insuranceSnapped ? (
              <OhSnapTextInsurance insuranceSnapped={insuranceSnapped} />
            ) : null}
            {/* <HighScoreTable /> */}
          </div>
          <LoadingNextRound />
        </div>
      ) : null}
    </div>
  );
};

export default OhSnap;
