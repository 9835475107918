import * as React from "react";

function FloatingCircleOrangeSvg(props) {
  return (
    <svg
      width={52}
      height={52}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter3_d)">
        <circle cx={26} cy={26} r={6} fill="url(#prefix__paint3_linear)" />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint3_linear"
          x1={26}
          y1={20}
          x2={32}
          y2={32}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBF1A" />
          <stop offset={0.99} stopColor="#FF741A" />
        </linearGradient>
        <filter
          id="prefix__filter3_d"
          x={0}
          y={0}
          width={52}
          height={52}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 0.995833 0 0 0 0 0.915669 0 0 0 0 0.709531 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default FloatingCircleOrangeSvg;
