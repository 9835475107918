import * as React from "react";

function LogoSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.261 1.573a4.106 4.106 0 00-2.116 1.126l-.756.756-1.863-.019a.73.73 0 00-.537.213L6.796 5.842a.365.365 0 00.258.622l1.389-.063-.432.432a.643.643 0 000 .909l4.247 4.247a.643.643 0 00.91 0l.436-.437-.068 1.394c0 .325.392.488.622.258l2.193-2.193a.73.73 0 00.213-.537l-.014-1.868.75-.75a4.106 4.106 0 001.127-2.117l.706-3.615A1.072 1.072 0 0017.876.867l-3.615.706zm-1.526 5.692a1.458 1.458 0 102.063-2.063 1.458 1.458 0 00-2.063 2.063z"
        fill="#fff"
      />
      <path
        d="M11.19 1.444c.44-.439.942-.802 1.488-1.08a9.997 9.997 0 00-9.75 2.565C-.975 6.834-.975 13.166 2.93 17.07c3.905 3.905 10.237 3.905 14.142 0a9.997 9.997 0 002.566-9.75 5.866 5.866 0 01-1.08 1.488l-.274.274a8.318 8.318 0 01-2.39 6.81 8.323 8.323 0 01-7.269 2.327A1.667 1.667 0 007.1 15.994a2.508 2.508 0 00-.147-1.769l.69-.69a.833.833 0 10-1.178-1.178l-.69.69a2.508 2.508 0 00-1.77-.147 1.667 1.667 0 00-2.225-1.524 8.323 8.323 0 012.327-7.269 8.318 8.318 0 016.81-2.39l.274-.273z"
        fill="#fff"
      />
      <path
        d="M6.807 10.342l2.85 2.851c.19.19.496.19.686 0l.045-.045c.105-.106.19-.23.25-.366l.297-.669-3.048-3.048-.669.296a1.21 1.21 0 00-.366.251l-.045.045a.484.484 0 000 .685z"
        fill="#fff"
      />
    </svg>
  );
}

export default LogoSvg;
