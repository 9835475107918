import React, { createContext, useContext, useState } from 'react';

const RetailTicketsContext = createContext();

export const useRetailTicketsContext = () => {
  return useContext(RetailTicketsContext)
}

export const RetailContextProvder = ({ children }) => {
  const [retailTickets, setRetailTickets] = useState([]);

  window.setRetailTickets =  (change) => {
    if (change.length) {
      // change.map(obj => {
      //   // Create a shallow copy of each object
      //   return { ...obj };
      // });
      const newArray = change.slice(0, Math.min(change.length, 16));
      setRetailTickets(newArray)
    } else {
      setRetailTickets([]);
    }

  };

  return <RetailTicketsContext.Provider 
      value={{retailTickets}} >{children}
    </RetailTicketsContext.Provider>
}