import React, { useEffect, useState } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import ProgressBar from "react-bootstrap/ProgressBar";
import rocketmanTransport from '../services/RocketmanTransport';

export default function RoundCounter() {

  const defTimeLeft = 3;
  const [timeLeft, setTimeLeft] = useState(defTimeLeft);
  const OFFSET = 74;

  useEffect(() => {
    if (timeLeft <= 0) {
      setTimeLeft(0);
      window.setShowRoundCounter(false);
    }
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 0.1);
    }, 100);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <div className="wftnr-holder next-round-loader">
      <ScrollAnimation
        animateOnce
        offset={OFFSET}
        delay={200}
        duration={1}
        animateIn="fadeInUp"
      >
        <div className={"wftnr-text " + (rocketmanTransport.r7css ? "r7css" : "")}>
          <ProgressBar now={(100 / defTimeLeft) * timeLeft - 10} />
        </div>
      </ScrollAnimation>
      <ScrollAnimation
        animateOnce
        offset={OFFSET}
        delay={100}
        duration={1}
        animateIn="fadeInUp"
      >
        <div className="wftnr-line"></div>
      </ScrollAnimation>
    </div>
  )
}
