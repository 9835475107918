import { useEffect, useRef } from "react";
import { CountUp } from "countup.js";
import { Odometer } from "../services/CustomOdometerDOtCommaSpacing";

// eslint-disable-next-line react/prop-types
export default function JackpotOdometer({ value, format, duration }) {
  // Create a ref to hold the CountUp instance
  const countUpInstanceRef = useRef(null);
  const countupRef = useRef(null); // Ref to the DOM element

  // Update the value on prop changes (excluding the initial render)
  useEffect(() => {
    if (countUpInstanceRef.current) {
      if (value < countUpInstanceRef.current.endVal) {
        countUpInstanceRef.current.reset();
        countUpInstanceRef.current = null;
        countUpInstanceRef.current = new CountUp(countupRef.current, value, {
          plugin: new Odometer({ duration: 0, lastDigitDelay: 0 }),
        });
        //countUpInstanceRef.current.reset();

        countUpInstanceRef.current.options.duration = 0.1;
        countUpInstanceRef.current.options.decimalPlaces = format;
        // countUpInstanceRef.current.options.plugin = CountUpPlagin;
        //countUpInstanceRef.current.reset();
        countUpInstanceRef.current.start();
      
      } else {
        countUpInstanceRef.current.options.duration = duration;
        countUpInstanceRef.current.update(value);
      }
    } else {
      // Initialize the CountUp instance on the first render
      initCountUp();
    }
  }, [value]); // Dependency array includes only `value`

  // Dynamically import and initialize CountUp
  function initCountUp() {
    countUpInstanceRef.current = new CountUp(countupRef.current, value, {
      plugin: new Odometer({ duration: 0, lastDigitDelay: 0 }),
    });

    if (!countUpInstanceRef.current.error) {
      // console.log("countUpInstanceRef.current", countUpInstanceRef.current)
      countUpInstanceRef.current.options.duration = duration;
      countUpInstanceRef.current.options.decimalPlaces = format;
      // countUpInstanceRef.current.options.plugin = CountUpPlagin;
      countUpInstanceRef.current.start();
    } else {
      console.error(countUpInstanceRef.current.error);
    }
  }

  // Return JSX with the ref attached to the element
  return (
    <>
      <div
        className="odometer"
        ref={countupRef} // Attach ref to the DOM element
      ></div>
    </>
  );
}
