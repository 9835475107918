
const lossGifUrls = [
  'https://media0.giphy.com/media/A05Zkc18G0tb2/giphy.gif',
  'https://media4.giphy.com/media/l4Ki5iMwlTt1ojWHC/giphy.gif',
  'https://media4.giphy.com/media/EXHHMS9caoxAA/giphy.gif',
  'https://media.giphy.com/media/ka6M66Z58QEcXadCd4/giphy.gif',
  'https://media1.giphy.com/media/3ohs4qw8hkPShGeanS/giphy.gif',
  'https://media1.giphy.com/media/YTJXDIivNMPuNSMgc0/giphy.gif',
  'https://media1.giphy.com/media/WRQBXSCnEFJIuxktnw/giphy.gif',
  'https://media.giphy.com/media/EimNpKJpihLY4/giphy.gif',
  'https://media3.giphy.com/media/ji6zzUZwNIuLS/giphy.gif',
];

export default lossGifUrls;
