import React, { useEffect, useState } from "react";

const Expire = ({stateChanger, ...props}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      setVisible(false);
      stateChanger(false);
    }, props.delay);

    return () => {
      clearTimeout(timer);
      
    }
  }, [props.delay,stateChanger]);

  return visible ? <div style={{zIndex: 9}}>{props.children}</div> : <div />;
};

export default Expire;
