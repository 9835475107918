import * as React from "react";

function TableInfoSvg(props) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 17a8.5 8.5 0 110-17 8.5 8.5 0 010 17zm.776-6.447c0 .213.172.386.386.386.213 0 .386.173.386.386v.386a.773.773 0 01-.773.773H7.73a.773.773 0 01-.773-.773v-.386c0-.213.173-.386.386-.386a.386.386 0 00.387-.387V8.885a.386.386 0 00-.387-.386.386.386 0 01-.386-.387v-.386c0-.427.346-.773.773-.773h.546a1 1 0 011 1v2.6zM8.5 6.18a.773.773 0 100-1.545.773.773 0 000 1.545z"
        fill="#FFBF1A"
      />
    </svg>
  );
}

export default TableInfoSvg;
