import * as React from "react";

function ArrowDropdownBottomSvg(props) {
  return (
    <svg
      width={10}
      height={5}
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.9 2.3l2.5 1.875a1 1 0 001.2 0L8.1 2.3c.769-.577.361-1.8-.6-1.8h-5c-.961 0-1.369 1.223-.6 1.8z"
        fill={props.color || "#FFBF19"}
        stroke={props.color || "#FFBF19"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowDropdownBottomSvg;
