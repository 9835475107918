import React, { useMemo } from 'react';
import { useRocketmanTransport } from '../rocketman/RocketmanTransportContext';
import AllBetsSubComponent from './AllBetsSubComponent';


export default function AllBets() {

  const rocketmanContext = useRocketmanTransport();
  const allBets = rocketmanContext.allPlayerBets;
  const currRound = rocketmanContext.currRound;
  const multipliersHistory = rocketmanContext.multipliersHistory;
  const cashoutTick = rocketmanContext.cashoutTick;
  const avatarId = rocketmanContext.avatarId;

  return (
    useMemo(() => 
    <AllBetsSubComponent allBets={allBets} currRound={currRound} multipliersHistory={multipliersHistory} avatarId={avatarId}  />
    ,[cashoutTick, Object.values(allBets).length, currRound, multipliersHistory.length, avatarId])
  )
}
