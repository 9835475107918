import React, { useEffect, useState } from 'react'
//import PrimaryButton from '../PrimaryButton'
import Currency from '../Currency/Currency'
import rocketmanTransport from '../../services/RocketmanTransport'
import translate from '../../services/Translation/lang'
import { currencyFormatter } from '../../services/Helpers/NumberFormatter'
import randomElemGenerator from '../../services/Helpers/random-html-generator'
import isRoundNumber from '../../services/Helpers/CheckIsRoundNumber';

export default function MeteorShowerMessage({ message, currentUser = false, claims }) {
  const [disabledSpam, setDisabledSpam] = useState(false);
  const [randomParentElement, setRandomParentElement] = useState("");
  const [randomChildElement, setRandomChildElement] = useState("");

  useEffect(() => {
    setRandomParentElement(randomElemGenerator());
    setRandomChildElement(randomElemGenerator());
  }, []);
  
  const onClick = (disabled) => {
    setDisabledSpam(true);

    const freeTicketCount = rocketmanTransport.freeTicketCount;
    const freeTicketMoney = rocketmanTransport.freeTicketMoney;
    const freeTicketSum = freeTicketCount * freeTicketMoney;

    if (disabled || disabledSpam) {
      return;
    }

    if (freeTicketSum < message.individualFreeBetAmount) {
      if(!disabledSpam && !disabled) {
        rocketmanTransport.claimMeteorTicket(message.ticketId);
        window.setYouAlreadyHaveFreeTickets(false);

      setTimeout(() => {
        setDisabledSpam(false);
      }, 2000);
      }
    } else {
      window.setYouAlreadyHaveFreeTickets(true);
      setDisabledSpam(false);
    }
  }  

  const maskedUsername = (username) => {
    if (!username || typeof username !== 'string') {
      return null; // Handle invalid username or non-string cases
    }
  
    if (username.length > 14) {
      return username.substring(0, 11) + '...';
    } else {
      return username;
    }
  }

  let ParentElementToRender;
  switch (randomParentElement) {
    case 'div':
      ParentElementToRender = 'div';
      break;
    case 'span':
      ParentElementToRender = 'span';
      break;
    case 'a':
      ParentElementToRender = 'span';
      break;
    case 'button':
      ParentElementToRender = 'div';
      break;
    default:
      ParentElementToRender = 'div';
  }

  let ChildElementToRender;
  switch (randomChildElement) {
    case 'div':
      ChildElementToRender = 'div';
      break;
    case 'span':
      ChildElementToRender = 'span';
      break;
    case 'a':
      ChildElementToRender = 'a';
      break;
    case 'button':
      ChildElementToRender = 'button';
      break;
    default:
      ChildElementToRender = 'p';
  }

  const primaryButtonStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    width: '100%',
    height: '26px',
    cursor: 'pointer',
    borderRadius: '18px',
    background: 'linear-gradient(116.37deg,#fabf41 16.45%,#ffbf1a 96.03%)', // Replace with your gradient values
    fontFamily: '"TitilliumWeb-Bold", Roboto-Bold', // Replace with your font family
    fontSize: '14px',
    lineHeight: 1,
    color: '#141945', // Replace with your text color
    padding: '0 1rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)',
    transition: 'background 0.6s ease-in-out',
  };
  
  const disabledButtonStyles = {
    opacity: 0.6,
    cursor: 'default',
  };

  return (
    <div className='w-100'>
      <p className='meteor-shower-title-header mb-2'>{translate('claim_your_free_bet')}</p>
      {Object.keys(claims)?.length ? Object.values(claims).map((claim,i) => {
        return (
          <div className='msm-row mb-2' key={i}>
            <div className='msm-amount mr-2'>
            {isRoundNumber(message.individualFreeBetAmount) ?
              currencyFormatter(message.individualFreeBetAmount, '###,###') : currencyFormatter(message.individualFreeBetAmount)}<Currency className="ml-1 msm-currency">{message.currency}</Currency>
            </div>
            <div className='msm-claim-btn'>{<div className='masked-username'>{maskedUsername(claim)}</div>}
            </div>
          </div>
        )
      }): null}
      
      {new Array(Math.max(message.noOfFreeBets - (Object.keys(claims)?.length ?? 0), 0)).fill(null).map((arr, i) => {
        const disabled = Object.keys(claims).find((claim) => {
          if (claim.indexOf("key") !== -1) {
            if (claim == `key-${rocketmanTransport.player_id}`) return claim;
          } else {
            if (claim == rocketmanTransport.player_id) return claim; 
          }
        });
        return (
          <div className='msm-row mb-2' key={i}>
            <div className='msm-amount mr-2'>
            {isRoundNumber(message.individualFreeBetAmount) ?
              currencyFormatter(message.individualFreeBetAmount, '###,###') : currencyFormatter(message.individualFreeBetAmount)}<Currency className="ml-1 msm-currency">{message.currency}</Currency>
            </div>
            {
               Math.random() < 0.5 ? (<ParentElementToRender style={{textAlign: 'right', display: 'none'}}>
              {!currentUser ? <ChildElementToRender style={{
                  ...primaryButtonStyles,
                  ...(disabled && disabledButtonStyles),
                }} disabled={disabled}  >{translate('claim')}</ChildElementToRender>
                  : <span>*****</span>}
              </ParentElementToRender>) : null
            }
            {
               Math.random() < 0.5 ? (<ParentElementToRender style={{textAlign: 'right', display: 'none'}}>
              {!currentUser ? <ChildElementToRender style={{
                  ...primaryButtonStyles,
                  ...(disabled && disabledButtonStyles),
                }} disabled={disabled}  >{translate('claim')}</ChildElementToRender>
                  : <span>*****</span>}
              </ParentElementToRender>) : null
            }
            <ParentElementToRender style={{textAlign: 'right'}}>
              {!currentUser ? <ChildElementToRender style={{
                  ...primaryButtonStyles,
                  ...(disabled && disabledButtonStyles),
                }} disabled={disabled} onClick={() => onClick(disabled)} >{translate('claim')}</ChildElementToRender>
                  : <span>*****</span>}
            </ParentElementToRender>
            {
               Math.random() < 0.5 ? (<ParentElementToRender style={{textAlign: 'right', display: 'none'}}>
              {!currentUser ? <ChildElementToRender style={{
                  ...primaryButtonStyles,
                  ...(disabled && disabledButtonStyles),
                }} disabled={disabled}  >{translate('claim')}</ChildElementToRender>
                  : <span>*****</span>}
              </ParentElementToRender>) : null
            }
            {
               Math.random() < 0.5 ? (<ParentElementToRender style={{textAlign: 'right', display: 'none'}}>
              {!currentUser ? <ChildElementToRender style={{
                  ...primaryButtonStyles,
                  ...(disabled && disabledButtonStyles),
                }} disabled={disabled}  >{translate('claim')}</ChildElementToRender>
                  : <span>*****</span>}
              </ParentElementToRender>) : null
            }
          </div>
        )
      })}
      <p className='meteor-shower-title-footer mt-2'><span>{translate('meteor_shower_total')}: </span><span>{currencyFormatter(Number(message.noOfFreeBets * message.individualFreeBetAmount))} {message.currency}</span></p>
    </div>
  )

}