import React from "react";

function StepMoneySvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect
        width="25"
        height="15"
        x="8.728"
        y="2"
        fill="url(#paint1_linear)"
        rx="3.333"
        transform="rotate(26.652 8.728 2)"
      ></rect>
      <g filter="url(#filter0_bi)">
        <rect
          width="25"
          height="15"
          x="1"
          y="11"
          fill="#fff"
          fillOpacity="0.6"
          rx="3"
        ></rect>
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 18.5a5.5 5.5 0 1011 0 5.5 5.5 0 00-11 0zm3.185 2.955c.425.062.976.116 1.655.162v.88h1.366v-.88c.594-.039 1.042-.197 1.343-.475.3-.285.451-.787.451-1.504 0-.486-.05-.857-.15-1.111-.1-.255-.251-.436-.452-.544-.193-.108-.459-.186-.798-.232l-1.297-.15a2.276 2.276 0 01-.37-.07c-.07-.023-.116-.058-.139-.104a1.14 1.14 0 01-.023-.278c0-.139.03-.235.092-.289.062-.062.166-.1.313-.116.154-.015.405-.023.752-.023.494 0 1.073.03 1.736.093v-1.39a8.721 8.721 0 00-1.458-.161v-.915H12.84v.926c-.602.047-1.06.205-1.377.475-.309.27-.463.729-.463 1.377 0 .401.035.73.104.984.077.255.224.467.44.637.216.17.54.281.972.335l1.158.15c.177.024.3.047.37.07.07.023.116.062.139.116a.638.638 0 01.035.255c0 .146-.024.25-.07.312-.038.062-.139.108-.3.139a6.087 6.087 0 01-.788.035c-.478 0-1.103-.031-1.875-.093v1.389z"
        clipRule="evenodd"
      ></path>
      <rect
        width="3"
        height="3"
        x="4"
        y="20"
        fill="#fff"
        rx="1.5"
        transform="rotate(-90 4 20)"
      ></rect>
      <rect
        width="3"
        height="3"
        x="20"
        y="20"
        fill="#fff"
        rx="1.5"
        transform="rotate(-90 20 20)"
      ></rect>
      <defs>
        <filter
          id="filter0_bi"
          width="40.099"
          height="30.099"
          x="-6.549"
          y="3.451"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="3.775"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="5.322"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow"></feBlend>
        </filter>
        <linearGradient
          id="paint1_linear"
          x1="25.774"
          x2="10.064"
          y1="9.969"
          y2="17.887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BC4F2"></stop>
          <stop offset="1" stopColor="#A7E623"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default StepMoneySvg;
