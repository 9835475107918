import React, { useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import ArrowDropdownBottomSvg from "../svg/arrow-dropdown-bottom-svg";
import TopWinsTab from "../bets-tabs/TopWinsTab";
import TopOddsTab from "../bets-tabs/TopOddsTab";
import BiggestOddsTab from "../bets-tabs/BiggestOddsTab";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Sticky from "react-sticky-el";
import soundFxPlayer from '../../services/Audio/SoundFxPlayer';
import translate from '../../services/Translation/lang';
import rocketmanTransport from '../../services/RocketmanTransport';

export default function BetWins() {

  const [timeFilter, setTimeFilter] = useState(translate('week'));
  const [dropDownArrow, setDropdownArrow] = useState(false);
  const handleSelect = (e) => {
    setTimeFilter(e);
  };

  return (
    <Tabs selectedTabClassName="selected-pod-tab" onSelect={() => { 
      soundFxPlayer.play('slider')
    }}>
      <Sticky
        topOffset={-74}
        className="sticky-header-holder"
        stickyClassName="sticky-header"
      >
        <header>
          <TabList className="pod-tabs-nav">
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('top_wins')}</Tab>
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('top_odds')}</Tab>
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('biggest_odds')}</Tab>
          </TabList>
          <div className="bet-wins-menu">
            <div className="bet-wins-menu-left">{translate('time_frame')}</div>
            <div className="bet-wins-menu-right">
              <Dropdown
                onSelect={handleSelect}
                onToggle={() => setDropdownArrow(!dropDownArrow)}
              >
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                >
                  {timeFilter}
                  <div
                    className={
                      "arrow-dropdown-bottom " +
                      (dropDownArrow ? "rotate" : "")
                    }
                  >
                    <ArrowDropdownBottomSvg
                      color={dropDownArrow ? "#070a2b" : "#FFBF19"}
                    />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className={timeFilter === translate('week') ? "active" : ""}
                    eventKey={translate('week')}
                  >
                    {translate('week')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={timeFilter === translate('month') ? "active" : ""}
                    eventKey={translate('month')}
                  >
                    {translate('month')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={timeFilter === translate('year') ? "active" : ""}
                    eventKey={translate('year')}
                  >
                    {translate('year')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </header>
      </Sticky>

      <div className="pod-tabs-panel-holder container">
        <TabPanel>
          <TopWinsTab timeFilter={timeFilter} />
        </TabPanel>
        <TabPanel>
          <TopOddsTab timeFilter={timeFilter} />
        </TabPanel>
        <TabPanel>
          <BiggestOddsTab timeFilter={timeFilter} />
        </TabPanel>
      </div>
    </Tabs>
  )
}
