import { showerMessageHelper } from "../Helpers/StringHelper";

export default function showMessageNow(myBets,chat,currentRound,myPlayerId) {
  try {
    if (!chat) return false;
    if (!Object.values(chat).length) return false;
    
    const chatArr = [...chat].reverse();
    const lastMessage = chatArr[chatArr.length - 1];
    if (lastMessage.player_id == myPlayerId) return true;
    
    if (!("msg_lvl") in lastMessage) return true;
    if (lastMessage.msg_lvl != 22) return true;
    //console.log(myBets,"myBets");
    const getToRound = currentRound - 5;
    const getLastFiveRounds = myBets.filter(c => c.round_id > getToRound);
    const amountPlayedInLastFiveRounds = getLastFiveRounds.reduce((sum, bet) => sum + bet.player_bet, 0);
    const induvidualFreeBetAmount = showerMessageHelper(lastMessage.msg)

    if (amountPlayedInLastFiveRounds > induvidualFreeBetAmount.individualFreeBetAmount) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.info("error shower checker", error)
    return false;
  }
}
