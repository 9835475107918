import React from 'react'
import translate from '../../services/Translation/lang'

export default function MaxMultiplier() {
  return (
    <div className='slide max-multiplier'>
      <p className='slider-text-big'>{translate('retail_max_mp_1')} <strong>x10.000</strong> {translate('retail_max_mp_2')}</p>
    </div>
  )
}
