import React from "react";
import lossGiffUrls from './assets/loss';

export default function Loss({submitGif}) {
  return (
    <div className="chat-gifs-container">
      {lossGiffUrls.map((url,i) => {
        return (
          <div className="chat-gifs-category" onClick={() => submitGif(url)} key={i}>
            <img alt="gif-url" src={url} />
          </div>
        )
      })}
    </div>)
}
