const menuIcons = {
  soundSettings: "/menu-icons/sound-setting.svg",
  musicSettings: "/menu-icons/music-settings.svg",
  promoHistory: "/menu-icons/promo-history.svg",
  provablyFair: "/menu-icons/provably-fair.svg",
  gameRules: "/menu-icons/game-rules.svg",
  betHistory: "/menu-icons/bet-history.svg",
};

export default menuIcons;
