import * as React from "react";

function ProvablyFairSvg(props) {
  return (
    <svg
      width={props.width || 10}
      height={props.height || 12}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.91 12C1.635 10.542 0 9.088 0 7.636V2.182c0-.546.273-.546 1.09-1.091C1.18 1.032 3.035 0 4.91 0c1.633 0 3 .545 3.817 1.09.818.546 1.091.546 1.091 1.092.015.159 0 4.636 0 5.454 0 1.455-1.636 2.91-4.909 4.364zM3.83 5.884c.26.26.683.26.943 0l1.702-1.702a.606.606 0 11.857.857L4.774 7.598a.667.667 0 01-.943 0L2.485 6.25a.606.606 0 11.857-.857l.49.49z"
        fill="#FFBF1A"
      />
    </svg>
  );
}

export default ProvablyFairSvg;
