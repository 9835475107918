import React, { useEffect, useRef, useState } from 'react'
import rocketmanTransport from '../../services/RocketmanTransport';
import soundFxPlayer from '../../services/Audio/SoundFxPlayer';
import { NumericFormat } from 'react-number-format';
import Currency from '../Currency/Currency';
import { currencyFormatter } from '../../services/Helpers/NumberFormatter';
import PrimaryButton from '../PrimaryButton';
import translate from '../../services/Translation/lang';

const MIN_PLAYERS = 1;
// const MAX_PLAYERS = 20;

export default function MeteorShowerPopup({setShowMeteorPopup}) {
  const [category, setCategory] = useState("");
  const [max, setMax] = useState(rocketmanTransport.meteor_shower_max_amount_per_player);
  const [min, setMin] = useState(0);
  const [maxPlayers, setMaxPlayers] = useState(rocketmanTransport.meteor_shower_max_players)
  const [noOfPlayers, setNoOfPlayers] = useState(0);

  const [value, setValue] = useState(rocketmanTransport.defBet.toFixed(rocketmanTransport.noOfDecimals));
  const myRef = useRef(null);

  useEffect(() => {
    setMax(rocketmanTransport.meteor_shower_max_amount_per_player)
  }, [rocketmanTransport.meteor_shower_max_amount_per_player]);

  useEffect(() => {
    setMaxPlayers(rocketmanTransport.meteor_shower_max_players)
  }, [rocketmanTransport.meteor_shower_max_players]);

  useEffect(() => {
    setMin(rocketmanTransport.minBet)
  }, [rocketmanTransport.minBet]);

  useEffect(() => {
    setNoOfPlayers(rocketmanTransport.meteor_shower_max_players >= 10 ? 10 : 1)
  }, [rocketmanTransport.minBet]);

  const executeScroll = () => myRef.current.scrollIntoView();

  const increase = () => {
    if (Number(value) + Number(rocketmanTransport.defBet) <= max) {
      soundFxPlayer.play("increase_amount_BTN");
      setValue((prevState) => {
        return Number(Number(prevState) + Number(rocketmanTransport.defBet)).toFixed(rocketmanTransport.noOfDecimals);
      });
    }
  };

  const decrease = () => {
    if (Number(value) - Number(rocketmanTransport.defBet) >= min) {
      soundFxPlayer.play("decrease_amount_BTN");
      setValue((prevState) => (Number(prevState) - Number(rocketmanTransport.defBet)
      ).toFixed(rocketmanTransport.noOfDecimals));
    } else {
      soundFxPlayer.play("decrease_amount_BTN");
      setValue(min.toFixed(rocketmanTransport.noOfDecimals));
    }
  };

  const handleIsAllowed = (values) => {
    const { floatValue } = values;

    if (floatValue < 1) {
      setValue(1);
    } else if (floatValue > max) {
      setValue(max);
    }

    return floatValue >= 1 && floatValue <= max;
  }

  const handleValueChange = (values, sourceInfo) => {
    const { floatValue } = values;

    if (floatValue > max) {
      setValue(max);
    } else {
      setValue(floatValue);
    }
  }

  const increasePlayers = () => {
    if (Number(noOfPlayers) + 1 <= maxPlayers) {
      soundFxPlayer.play("increase_amount_BTN");
      setNoOfPlayers((prevState) => {
        return Number(Number(prevState) + 1);
      });
    }
  };

  const decreasePlayers = () => {
    if (Number(noOfPlayers) - 1 >= MIN_PLAYERS) {
      soundFxPlayer.play("decrease_amount_BTN");
      setNoOfPlayers((prevState) => Number(prevState) - 1);
    } else {
      soundFxPlayer.play("increase_amount_BTN");
      setNoOfPlayers(MIN_PLAYERS);
    }
  };

  const handlePlayerValueChange = (value) => {
    if (value > maxPlayers) {
      setNoOfPlayers(maxPlayers);
    } else {
      setNoOfPlayers(value.value);
    }
  }

  const handleNoOfPlayersIsAllowed = (values) => {

    const { floatValue } = values;

    if (floatValue < 1) {
      setNoOfPlayers(1);
    } else if (floatValue > maxPlayers) {
      setNoOfPlayers(maxPlayers);
    }

    return floatValue >= 1 && floatValue <= maxPlayers;
  }

  const handleStartShower = () => {
    // if (Number(Number(value) * Number(noOfPlayers)) > Number(rocketmanTransport.balance)) {
    //   window.setShowNoFunds(true);
    //   return;
    // }
    rocketmanTransport.offerMeteorShower(value,noOfPlayers)
    setShowMeteorPopup(true);
  }

  useEffect(() => {
    //executeScroll();
  }, [category]);

  return (
    <div className="chat-gifs-holder meteor-shower-holder">
      <div style={{ position: "absolute", top: 0 }} ref={myRef}></div>
      <div className="chat-gifs-title meteor">
        {translate('meteor_shower')}
      </div>

      <p className='mb-2 text-center'>{translate('this_feature_will_give')}</p>

      <div className='aap-input-holder' >
        <div className="aap-label">{translate('amount_per_player')}, <Currency >{rocketmanTransport.myCurrency}</Currency></div>
        <div className={"play-now-number-input "}>
          <button
            onClick={() => decrease()}
            className="play-now-number-input-decrese"
          >
            <div className="minus"></div>
          </button>

          <NumericFormat
            valueIsNumericString={true}
            value={value}
            thousandSeparator=","
            decimalSeparator="."
            allowNegative={false}
            decimalScale={rocketmanTransport.noOfDecimals || 2}
            onValueChange={handleValueChange}
            isAllowed={handleIsAllowed}
          // fixedDecimalScale={rocketmanTransport.noOfDecimals > 0 ? true : false}
          />
          <button
            onClick={() => increase()}
            className="play-now-number-input-increse"
          >
            <div className="plus"></div>
          </button>
        </div>
      </div>

      <div className='aap-input-holder' >
        <div className="aap-label">{translate('no_of_players')}</div>
        <div className={"play-now-number-input "}>
          <button
            onClick={() => decreasePlayers()}
            className="play-now-number-input-decrese"
          >
            <div className="minus"></div>
          </button>

          <NumericFormat
            valueIsNumericString={true}
            value={noOfPlayers}
            allowNegative={false}
            onValueChange={handlePlayerValueChange}
            isAllowed={handleNoOfPlayersIsAllowed}
          />
          <button
            onClick={() => increasePlayers()}
            className="play-now-number-input-increse"
          >
            <div className="plus"></div>
          </button>
        </div>
      </div>

      <div className='meteor-shower-total-bet' >
        <h6>{translate('total')} <Currency >{rocketmanTransport.myCurrency}</Currency></h6>
        <h2>{currencyFormatter(Number(Number(value) * Number(noOfPlayers)))}</h2>
      </div>

      <PrimaryButton
        onClick={() => handleStartShower()}
      >
        {translate('shower')} {currencyFormatter(Number(Number(value) * Number(noOfPlayers)))}<Currency>&nbsp;{rocketmanTransport.myCurrency}</Currency>
      </PrimaryButton>
    </div>
  );
}
