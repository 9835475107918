import { useState, useEffect } from "react";

export default function useGameHeight() {
  const [clientHeight, setClientHeight] = useState(document.body.scrollHeight);
  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const sendGameHeight = () => {
    if (inIframe()) {
      if ('parentIFrame' in window) {
        window.parentIFrame.getPageInfo((data) => {
          setClientHeight(data.clientHeight);  
          document.body.style.height = data.clientHeight + 'px';
        })
      }
      window.parent.postMessage(
        {
          eventName: "game_to_iframe",
          response: Number(clientHeight),
        },
        "*"
      );
    }
  };

  window.onmessage = (event) => {
    if (event.data.eventName == "iframe_to_game") {
      sendGameHeight();
    }
  };

  useEffect(() => {
    if (inIframe()) { 
      sendGameHeight()
    }
  }, []);

  return clientHeight;
}
