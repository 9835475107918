import { useEffect, useState } from "react";
import rocketmanTransport from "../services/RocketmanTransport";

const Username = (props) => {
  const [isVip, setIsVip] = useState(rocketmanTransport.userIsVip);

  window.setIsVip = setIsVip;
  
  return (
    <p className={props.className}>
      {props.username}
      {isVip ? (
        <img
          className="header-vip-avatar-marker"
          src="/svg/crown.svg"
          alt="crown"
        />
      ) : null}
    </p>
  );
};

export default Username;
