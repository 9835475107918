import { MemoryRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Chat from "./pages/Chat";
import Bets from "./pages/Bets";
import HowToPlay from "./pages/HowToPlay";
import DetailedRules from "./pages/DetailedRules";
import PlayNow from "./pages/PlayNow";
import HeaderLogic from "./components/main/HeaderLogic";
import ProvablyFairSystem from "./pages/ProvablyFairSystem";
import UserAvatars from "./pages/UserAvatars";
import BetInfo from "./pages/BetInfo";
import { RocketmanContextProvder } from "./components/rocketman/RocketmanTransportContext";
import BetVerify from "./pages/BetVerify";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { useEffect, useRef, useState } from "react";
import SessionDisconnected from "./components/main/SessionDisconnected";
import rocketmanTransport from "./services/RocketmanTransport";
import useGameHeight from "./hooks/sendGameHeight";
import { initializeShareIntervalAfterReload } from "./services/SpamFilter/GifShareSpamFilter";
import { initializeShareIntervalAfterReloadTicket } from "./services/SpamFilter/TicketShareSpamFilter";
import Confetti from 'react-confetti'
import MainRetail from "./components/Retail/MainRetail";

var confettiTimer;

function App() {
  
  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");
  const isMounted = useRef(false);
  const { width } = useWindowDimensions();
  const [gameDissconnected, setGameDissconnected] = useState(false);
  const [runConfetti, setRunConfetti] = useState(false);
  const [initialConfetti, setInitialConfetti] = useState(0);
  window.setGameDissconnected = (val) => {
    rocketmanTransport.toggleSound(false);
    rocketmanTransport.toggleMusic(false);
    setGameDissconnected(val);
  }

  window.setRunConfetti = setRunConfetti;
 
  useGameHeight();

  useEffect(() => {
    initializeShareIntervalAfterReload();
    initializeShareIntervalAfterReloadTicket();
    rocketmanTransport.desktopResolution = width >= 1024 ? true : false;
  },[]);

  useEffect(() => {
    if (isMounted.current) {
      if (width >= 1024) {
        rocketmanTransport.desktopResolution = true;
        rocketmanTransport.resize()
      } else if (width < 1024) {
        rocketmanTransport.desktopResolution = false;
        rocketmanTransport.resize()
      }
    } 
    isMounted.current = true;
  },[width]);

  useEffect(() => {
    if(runConfetti) {
      setInitialConfetti(1)
    }
    confettiTimer = setTimeout(function () {
      
      if(runConfetti) {
        setRunConfetti(false)

      }
    }, 10000);
  }, [runConfetti])

  useEffect(() => {

    return () => {
      clearTimeout(confettiTimer);
    };
  }, []);

  const compoToRender = () => {
    if (version === "desktop" && width > 1024) {
      if (rocketmanTransport.retail) {
        return MainRetail;
      } else {
        return Home;
      }
    } else {
      return PlayNow;
    }
  }


  if (gameDissconnected) {
    return (<SessionDisconnected gameDissconnected={gameDissconnected} />)
  } else {
    return (
      <MemoryRouter>
        <RocketmanContextProvder>
          <div className="App full-height">
            <div className="confetti-canvas-holder">
              <Confetti width={window.innerWidth || 300} 
                height={window.innerHeight || 200} numberOfPieces={800} opacity={initialConfetti}
                   recycle={runConfetti} />
            </div>
            <HeaderLogic  width={width} />
            <Switch>
              <Route path="/home" component={rocketmanTransport.retail ? MainRetail : Home} />
              <Route path="/menu" component={Menu} />
              <Route path="/chat" component={Chat} />
              <Route path="/bets/:selectedTab?/:historyTab?" component={Bets} />
              <Route path="/bet-info" component={BetInfo} />
              <Route path="/bet-verify" component={BetVerify} />
              <Route path="/how-to-play" component={HowToPlay} />
              <Route path="/user-avatars" component={UserAvatars} />
              <Route path="/detailed-rules" component={DetailedRules} />
              <Route
                path="/provably-fair-system"
                component={ProvablyFairSystem}
              />
            </Switch>
            <Route path="/" component={compoToRender()}/>
          </div>
        </RocketmanContextProvder>
      </MemoryRouter>
    );
  }
}

export default App;
