import React, { useState, useMemo, useRef } from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import JackpotsHeader from '../JackpotsHeader';
import HeaderDialogWarning from '../HeaderDialogWarning';
import Expire from '../main/Expire';
import LoadingScreen from '../main/LoadingScreen';
import LostConnection from '../main/LostConnection';
import MaintenanceMode from '../main/MaintenanceMode';
import FullscreenMode from '../main/FullscreenMode';
import Rocketman from '../rocketman/Rocketman';
import RetailSlider from './RetailSlider';
import rocketmanTransport, {
  urlParams,
} from '../../services/RocketmanTransport';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import RetailFooter from './RetailFooter';

export default function RetailNow() {
  const OFFSET = 74;
  const [gameStarted, setGameStarted] = useState(
    rocketmanTransport.gameStarted
  );

  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [warning, setWarning] = useState("");
  const [lostConnection, setLostConnection] = useState(false);

  const [fullScreen, setFullScreen] = useState(rocketmanTransport.launcherFullScreen);
  
  const { width } = useWindowDimensions();
  const hideRocketman = useRef(false);
  const demoCompanyId = rocketmanTransport.launcherCompanyId;

  window.setGameStarted = setGameStarted;
  window.setWarning = setWarning;
  window.setLostConnection = setLostConnection;
  window.setFullScreen = setFullScreen;
  window.setMaintenanceMode = setMaintenanceMode;


  const appElem = document.querySelector(".App");
  const rootElem = document.getElementById("root");

  hideRocketman.current = false;
  if (appElem && rootElem) {
    document.querySelector("html").classList.add("full-height");
    document.querySelector("body").classList.add("full-height");
    document.getElementById("root").classList.add("full-height");
    document.querySelector(".App").classList.add("full-height");
  }

  document.querySelector("body").classList.add("retail");

  return (
    <><div
      className="rocketman-container"
      style={{
        paddingTop: 0,
        display: "flex",
      }}
    >
      {demoCompanyId == 1 ? (
        <div className="demo-badge">
          <div className="sticker">DEMO</div>
        </div>
      ) : null}
        <ScrollAnimation
          animateOnce
          offset={OFFSET}
          delay={100}
          duration={1}
          animateIn="fadeInDown"
          className={width < 1024 && rocketmanTransport.launcherHideHeader == 1 ? "jackpots-header-holder top-0" : "jackpots-header-holder"}
          style={rocketmanTransport.retail ? {opacity: 1} : {}}
        >
          <JackpotsHeader
            jackpot={9458.83}
            rocketpot={1233.51}
            boosterpot={568.22}
          />
          <ScrollAnimation
            className="cashout-header-dialog-holder"
          >
            {warning && (
              <Expire delay="5000" stateChanger={setWarning}>
                <HeaderDialogWarning msg={warning} />
              </Expire>
            )}

          </ScrollAnimation>
        </ScrollAnimation>
      <div className="page-holder with-bottom-navigator play-now-page-holder">
        {useMemo(
          () => (
            <LoadingScreen
              gameStarted={gameStarted}
              warning={warning ? warning : ""}
            />
          ),
          [gameStarted, warning]
        )}
        {useMemo(
          () => (
            <LostConnection lostConnection={lostConnection} />
          ),
          [lostConnection]
        )}
        {useMemo(
          () => (
            <MaintenanceMode maintenanceMode={maintenanceMode} />
          ),
          [maintenanceMode]
        )}
        {urlParams.get("version") !== "desktop" &&
          fullScreen && <FullscreenMode />}
        <Rocketman />
        {/* {useMemo(() => {
          if (animation) {
            if (!rocketmanTransport.retail) {
              return <Rocketman />;
            } else if (rocketmanTransport.retail && (showScreen === 'READY_TO_FLY' || showScreen === 'FLY')) {
              return <Rocketman />;
            } else {
              return null;
            }
          } else {
            return <LowEndRocketman />;
          }
        }, [animation, showScreen, rocketmanTransport.retail])} */}
        <div className="rocketman-scroll-holder">
          <div className={"container retail"}>
            
          </div>
          <RetailFooter />
        </div>
      </div>
      <RetailSlider />
    </div>
    {/* {useMemo(
        () => (
          <BottomTabNavigator totalBets={totalBets} />
        ),
        [totalBets]
      )} */}
    </>
  );
}
