import React from "react";
import translate from "../../services/Translation/lang";
import ElbetLogoLoader from "../svg/elbet-logo-loader";
import ElbetLogoLoaderLower from "../svg/elbet-logo-loader-lower";

const MaintenanceMode = ({maintenanceMode}) => {

  return (
    <div className={["loading-screen", maintenanceMode ? "" : "hide"].join(" ")} >
      <ElbetLogoLoader />
      <p className="loading-screen-text text-center">Maintenance in progress - Please check back later </p>
      <div className="loading-screen-footer">
        From
        <ElbetLogoLoaderLower />
      </div>
    </div>
  );
};

export default MaintenanceMode;
