import React from "react";
import LowEndRocketmanMp from "./LowEndRocketmanMp";

export default function LowEndRocketman() {
  return (
    <div className="animation-off">
      <div className="animation-off-multiplier"><LowEndRocketmanMp /></div>
    </div>
  );
}
