import * as React from "react";

function ArrowUpPlayNowSvg(props) {
  return (
    <svg
      width={15}
      height={7}
      viewBox="0 0 15 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 5l5.5-3L13 5"
        stroke="#fff"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowUpPlayNowSvg;
