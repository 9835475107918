import React from "react";

const Avatar = (props) => {
  return (
    <div className={"avatar-holder " + (props.vipAvatar ? "avatar-holder-vip" : "")}>
      {
        props.vipAvatar ? <img className="vip-avatar-marker" src="/svg/crown.svg" alt="crown" /> : null
      }
      <img
        className="avatar-image"
        src={props.avatar}
        alt={props.avatarTitle}
      />
      <p className="avatar-title">{props.avatarTitle}</p>
    </div>
  );
};

export default Avatar;
