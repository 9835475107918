import * as React from "react";

function ArrowLeftButtonSvg(props) {
  return (
    <svg
      width={11}
      height={8}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5 1L2 4l3 3M2 4h9" stroke="#141945" strokeWidth={2} />
    </svg>
  );
}

export default ArrowLeftButtonSvg;
