import React from 'react';
import { useRocketmanTransport } from './RocketmanTransportContext';


export default function LowEndRocketmanMp() {

  const rocketmanContext = useRocketmanTransport();
  const mp = rocketmanContext.mp;

  return (
    <div>
      {mp.toFixed(2)}x
    </div>
  )
}
