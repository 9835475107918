import * as React from "react";

function MenuInactiveSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 9a4.5 4.5 0 110-9 4.5 4.5 0 010 9zM0 15.5a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0zm11 0a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0zm0-11a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0zM15.5 7a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM7 4.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM15.5 18a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM7 15.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        fill="#fff"
      />
    </svg>
  );
}

export default MenuInactiveSvg;
