import React from "react";

function AvatarSelectedSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="6"
      fill="none"
      viewBox="0 0 8 6"
    >
      <path
        stroke="#967217"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 3l2 2 4-4"
      ></path>
    </svg>
  );
}

export default AvatarSelectedSvg;
