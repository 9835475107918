import React, { useState, useEffect } from "react";
import StepDicesSvg from "./svg/step-dices-svg";
import StepRocketSvg from "./svg/step-rocket-svg";
import StepMoneySvg from "./svg/step-money-svg";
import rocketmanTransport from "../services/RocketmanTransport";

const DetailedStep = (props) => {

  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  const [showCurrency, setShowCurrency] = useState(rocketmanTransport.isCurrencyVisible);

  useEffect(() => {
      setShowCurrency(rocketmanTransport.isCurrencyVisible)
  }, [rocketmanTransport.isCurrencyVisible])

  useEffect(() => {
    if (language === 'rs') {
      setLanguage('sr');
    }
  },[language]);
  return (
    <div className="detailed-step">
      <div className="detailed-step-top">
        {props.stepNumber === 1 ? (
          <StepDicesSvg />
        ) : props.stepNumber === 2 ? (
          <StepRocketSvg />
        ) : props.stepNumber === 3 ? (
          <StepMoneySvg />
        ) : null}
      </div>
      <h4 className="detailed-step-title">
        0{props.stepNumber}. {props.title}
      </h4>
      <div className="detailed-step-image">
        {props.stepNumber === 1 ? (
          (showCurrency ? <img src={`place_bet/place-bet-${language}.png`} alt="step 1" /> : <img src={`place_bet/place-bet-en-currency-hidden.png`} alt="step 1" />)
        ) : props.stepNumber === 2 ? (
          <img src={`watch-rocket/watch-rocket.png`} alt="step 2" />
        ) : props.stepNumber === 3 ? (
          (showCurrency ? <img src={`cashout/cashout-${language}.png`} alt="step 3" /> : <img src={`cashout/cashout-en-currency-hidden.png`} alt="step 3" />)
        ) : null}
      </div>
      <p className="detailed-step-text">{props.description}</p>
    </div>
  );
};

export default DetailedStep;
