import React, { useEffect, useState } from 'react';
import translate from '../../services/Translation/lang';

export default function LoadingNextRound() {

  const [timer, setTimer] = useState(2);

  useEffect(() => {
    let countDown = setInterval(() => {
      if (timer < 1) {
        setTimer(0);
        window.setRocketSnapped(false);
      } else {
        setTimer(() => timer - 1);
      }
    }, 1000);

    return () => clearInterval(countDown);
  },[timer])

  return (
    <div className="oh-snap-timer">
      <div className="oh-snap-timer-body"><p>{translate('loading_next_round')}{timer}s</p></div>
    </div>
  )
}
