
export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  // Directly return the joined string
  return splitStr.join(' '); 
}

export const showerMessageHelper = (msg) => {
  const [ticketId, noOfFreeBets, individualFreeBetAmount, ...currencyChars] = msg.split(/;|\s/);

  // Join the remaining characters after space to form the currency
  const currency = currencyChars.join('');

  return {
    ticketId: parseInt(ticketId),
    noOfFreeBets: parseInt(noOfFreeBets),
    individualFreeBetAmount: parseFloat(individualFreeBetAmount),
    currency: currency,
  };
}

export const clearDotsAndCommas = (str) => {
  if (str) {
    return str.replace(/[.,]/g, ""); // Replace all dots and commas with empty strings
  }
}
  
