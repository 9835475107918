import React, { useEffect, useState } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import storage from "../../services/Storage/Storage";
import translate from "../../services/Translation/lang";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";

const UserChangeUserName = () => {
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  //const INPUT_REGX = /[A-Za-z0-9]+$|^$/;
  // const INPUT_REGX = /^[A-Za-z0-9\u0400-\u04FF]*$/;
  const INPUT_REGX = /^(?!.*rocketman)[A-Za-z0-9\u0400-\u04FF]*$/i;
  const INPUT_LENGTH = 30;
  const INPUT_MIN_LENGTH = 2;
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");

  // handle change event
  const handleChange = (e) => {
    e.preventDefault(); // prevent the default action
    setUsernameErrorMessage("");
    if (INPUT_REGX.test(e.target.value) && String(e.target.value).length <= INPUT_LENGTH) {
      setUsername(e.target.value); // set name to e.target.value (event)
    }
  };

  const setNewUsername = () => {
    if (!username) {
      setUsernameErrorMessage(translate('username_must_be_at_least') + " " + (INPUT_MIN_LENGTH + 1) + " " + translate('characters_long'))
      return;
    }
    if (username.length <= INPUT_MIN_LENGTH) {
      setUsernameErrorMessage(translate('username_must_be_at_least') + " " + (INPUT_MIN_LENGTH + 1) + " " + translate('characters_long'))
      return;
    }
    const parser = new URL(window.location);
    storage.set("showUsername", true);
    setShow(!show);
    storage.set("userDefinedUsername", username);
    window.location = parser.href;
  };

  useEffect(() => {
    rocketmanTransport.setChangeUsernameFocused(show);
  }, [show]);

  const cancelUserName = () => {
    setShow(!show);
  };

  window.setShowUserName = setShow;
  window.setShowUserNameDesktop = setShow;

  return (
    <div
      className={
        "fullscreen-mode user-defined-username " + (show ? "" : "hide")
      }
    >
      <div onClick={() => setShow(false)} className="user-defined-username-background"></div>
      <div className="user-defined-username-body">
        <h3>{translate("enter_username")}</h3>
        <input
          autoFocus
          key="editor1"
          className="defined-username"
          placeholder={translate("username")}
          onChange={(e) => handleChange(e)}
          value={username}
          onPaste={(e)=>{
            e.preventDefault()
            return false;
          }} 
        ></input>
        <p className="user-error-message">{usernameErrorMessage}</p>
        <div className="pop-up-close">
          <PrimaryButton
            title={translate("save")}
            onClick={() => setNewUsername()}
          />
          <SecondaryButton
            title={translate("cancel")}
            onClick={() => cancelUserName()}
          />
        </div>
      </div>
    </div>
  );
};

export default UserChangeUserName;
