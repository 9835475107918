import React from "react";

const MainTitle = (props) => {
  return (
    <h5 disabled={props.disabled} className={"text-white " + props.className}>
      {props.title}
    </h5>
  );
};

export default MainTitle;
