

class WindowBridgeAbstract {

  /**
   * Constructor
   */
  constructor() {
    this.transport = window.parent;
    this.useIntegration = false;
    this.eventDebug = new URLSearchParams(window.location.search).get('eventDebug');
  }

  /**
   * 
   * @param {*} msg 
   * @returns 
   */
  postMessage(msg) {
    if (!this.useIntegration) {
      return;
    }

    if (this.eventDebug === 'true') {
      console.log("EVENT POSTED :", msg);
    }
    this.transport.postMessage(JSON.stringify(msg), '*');
  }
}

export default WindowBridgeAbstract;
