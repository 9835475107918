import React from "react";
import translate from "../../services/Translation/lang";

export default function GameExplanation() {
  return (
    <div className="slide game-explanation">
      <p className="slider-text-big">
        {translate('game_explanation_1')}
        <br />
        <strong>{translate('game_explanation_2')}</strong>
      </p>
    </div>
  );
}
