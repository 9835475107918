import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { acceptedLang } from '../../services/Helpers/AcceptedLangs';
import rocketmanTransport, { urlParams } from '../../services/RocketmanTransport';
import translate from '../../services/Translation/lang';
import MyBetsTr from "../MyBetsTr";
import { useRocketmanTransport } from '../rocketman/RocketmanTransportContext';

export default function MyBetsTable(props) {

  const rocketmanContext = useRocketmanTransport();
  const currRound = rocketmanContext.currRound;
  const [refreshMyBetsTable, setRefreshMyBetsTable] = useState(String(new Date().valueOf()));
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  const isMounted = useRef(false);

  useEffect(() => {
    if(isMounted.current === false && language) {
      isMounted.current = true;
    }
    if (language === 'CN') {
      moment.locale('zh-cn');
    } else if (language === 'rs') {
      moment.locale('rs');
    } else {
      moment.locale(language);
    }
    
    return () => isMounted.current = false;
  },[language]);

  window.setRefreshMyBetsTable = (d) => {
    if (isMounted.current) setRefreshMyBetsTable(d);
  }

  window.setMyBetsLang = setLanguage;
  moment.locale(acceptedLang(urlParams.get('language') || urlParams.get('lang')) ? urlParams.get('language') || urlParams.get('lang') : "en");

  return(
    useMemo(() => (
      <table className="bets-table my-bets-table">
        <thead>
          <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
            <th>{translate('date')}</th>
            <th>{translate('bet_bets_tab')}</th>
            <th>{translate('coeff')}</th>
            <th>{translate('win')}</th>
            {rocketmanTransport.isChatVisible ? <th></th> : null}
            
          </tr>
        </thead>
        <tbody>
          {props.myBets.slice().reverse().map((myBet,index) => {
            return (
              <MyBetsTr key={index} date={moment.utc(myBet.created_time).local().format('DD.MM.YY HH:mm:ss')} currRound={currRound} bet={myBet.player_bet} coefficient={myBet.win ? Number(myBet.win) / Number(myBet.player_bet) : ""} 
                betEur={myBet.bet_eur} win={myBet.win ? myBet.win : ""} currency={myBet.currency_code} winEur={myBet.win_eur ? myBet.win_eur : ""} valute={props.myCurrency} round={myBet?.round_id} />
            )
          })}
        </tbody>
      </table>
    ),[refreshMyBetsTable, currRound, props?.myBets, isMounted.current])
  )
}
