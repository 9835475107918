let NO_OF_AVATARS = 60;

let avatars = {};

export default function preloadAvatars() {
    for (let i = 0; i < NO_OF_AVATARS; i++) {
        let avatarImg = new Image();
        avatarImg.src = `/avatars/Avatar_${i + 1}.svg`;
        avatarImg.onload = () => {
            avatars[`avatar${i}`] = avatarImg;
        };
    }
}