import * as React from "react";

function BetsSvg(props) {
  return (
    <svg
      width={20}
      height={18}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 14.93V16h1a2 2 0 012 2H6a2 2 0 012-2h1v-1.07a6.999 6.999 0 01-4.781-2.982C1.505 11.574 0 9.223 0 6V3a2 2 0 012-2h1.268A2 2 0 015 0h10a2 2 0 011.732 1H18a2 2 0 012 2v3c0 3.223-1.505 5.574-4.219 5.948A6.999 6.999 0 0111 14.929zM3 3H2v3c0 1.531.39 2.675 1.128 3.338A7.036 7.036 0 013 8V3zm14 5V3h1v3c0 1.531-.39 2.675-1.128 3.338.084-.433.128-.88.128-1.338z"
        fill="#fff"
      />
    </svg>
  );
}

export default BetsSvg;
