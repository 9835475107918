export default {
  "us": {
    "chatServer": "wss://chat.rocketman.elbet.com:8765",
    "gameServers": "https://server.rocketman.us.elbet.com:9223"
  },
  "retail": {
    "chatServer": "wss://chat.rocketman.elbet.com:8765",
    "gameServers": "https://server.rocketmanretail.elbet.com:9000"
  },
  "default": {
    "chatServer": "wss://chat.rocketman.elbet.com:8765",
    "gameServers": "https://server.rocketman.elbet.com:9223"
  }
}