import { Howl } from "howler";
import rocketmanTransport from "../RocketmanTransport";

var VOLUME = 0.1;

const urlParams = new URLSearchParams(window.location.search);
const region = urlParams.get("region");

class SoundFxPlayer {
  constructor() {
    if (region == 'retail') return
    this.sound_files = {
      all_bets_list_appear_1: new Howl({
        src: ["SFX/all_bets_list_appear_1.mp3"],
        volume: VOLUME,
      }),
      all_bets_list_appear_2: new Howl({
        src: ["SFX/all_bets_list_appear_2.mp3"],
        volume: VOLUME,
      }),
      astronaut_promo_credit_1: new Howl({
        src: ["SFX/astronaut_promo_credit_1.mp3"],
        volume: VOLUME,
      }),
      astronaut_promo_credit_2: new Howl({
        src: ["SFX/astronaut_promo_credit_2.mp3"],
        volume: VOLUME,
      }),
      astronaut_promo_credit_3: new Howl({
        src: ["SFX/astronaut_promo_credit_3.mp3"],
        volume: VOLUME,
      }),
      betBTN: new Howl({
        src: ["SFX/betBTN.mp3"],
        volume: VOLUME,
      }),
      cancelBTN: new Howl({
        src: ["SFX/cancelBTN.mp3"],
        volume: VOLUME,
      }),
      cashout_1: new Howl({
        src: ["SFX/cashout_1.mp3"],
        volume: VOLUME,
      }),
      cashout_2: new Howl({
        src: ["SFX/cashout_2.mp3"],
        volume: VOLUME,
      }),
      cashout_close: new Howl({
        src: ["SFX/cashout_close.mp3"],
        volume: VOLUME,
      }),
      chat_appear_1: new Howl({
        src: ["SFX/chat_appear_1.mp3"],
        volume: VOLUME,
      }),
      chat_appear_2: new Howl({
        src: ["SFX/chat_appear_2.mp3"],
        volume: VOLUME,
      }),
      checking_for_spam: new Howl({
        src: ["SFX/checking_for_spam.mp3"],
        volume: VOLUME,
      }),
      controlBTN: new Howl({
        src: ["SFX/controlBTN.mp3"],
        volume: VOLUME,
      }),
      decrease_amount_BTN: new Howl({
        src: ["SFX/decrease_amount_BTN.mp3"],
        volume: VOLUME,
      }),
      final_payout_1: new Howl({
        src: ["SFX/final_payout_1.mp3"],
        volume: VOLUME,
      }),
      final_payout_2: new Howl({
        src: ["SFX/final_payout_2.mp3"],
        volume: VOLUME,
      }),
      final_payout_3: new Howl({
        src: ["SFX/final_payout_3.mp3"],
        volume: VOLUME,
      }),
      final_payout_4: new Howl({
        src: ["SFX/final_payout_4.mp3"],
        volume: VOLUME,
      }),
      increase_amount_BTN: new Howl({
        src: ["SFX/increase_amount_BTN.mp3"],
        volume: VOLUME,
      }),
      menu_switch_1: new Howl({
        src: ["SFX/menu_switch_1.mp3"],
        volume: VOLUME,
      }),
      menu_switch_2: new Howl({
        src: ["SFX/menu_switch_2.mp3"],
        volume: VOLUME,
      }),
      planet: new Howl({
        src: ["SFX/planet.mp3"],
        volume: VOLUME,
      }),
      posting: new Howl({
        src: ["SFX/posting.mp3"],
        volume: VOLUME,
      }),
      promo_credit_1: new Howl({
        src: ["SFX/promo_credit_1.mp3"],
        volume: VOLUME,
      }),
      promo_credit_2: new Howl({
        src: ["SFX/promo_credit_2.mp3"],
        volume: VOLUME,
      }),
      promo_credit_3: new Howl({
        src: ["SFX/promo_credit_3.mp3"],
        volume: VOLUME,
      }),
      rocket_blast_off_1: new Howl({
        src: ["SFX/rocket_blast_off_1.mp3"],
        volume: VOLUME,
      }),
      rocket_blast_off_2: new Howl({
        src: ["SFX/rocket_blast_off_2.mp3"],
        volume: VOLUME,
      }),
      rocket_engine_1: new Howl({
        src: ["SFX/rocket_engine_1.mp3"],
        volume: VOLUME,
      }),
      rocket_engine_2: new Howl({
        src: ["SFX/rocket_engine_2.mp3"],
        volume: VOLUME,
      }),
      rocket_engine_3: new Howl({
        src: ["SFX/rocket_engine_3.mp3"],
        volume: VOLUME,
      }),
      rocket_engine_4: new Howl({
        src: ["SFX/rocket_engine_4.mp3"],
        volume: VOLUME,
      }),
      rocket_explosion_1: new Howl({
        src: ["SFX/rocket_explosion_1.mp3"],
        volume: VOLUME,
      }),
      rocket_explosion_2: new Howl({
        src: ["SFX/rocket_explosion_2.mp3"],
        volume: VOLUME,
      }),
      rocket_explosion_3: new Howl({
        src: ["SFX/rocket_explosion_3.mp3"],
        volume: VOLUME,
      }),
      rocket_explosion_4: new Howl({
        src: ["SFX/rocket_explosion_4.mp3"],
        volume: VOLUME,
      }),
      rocket_explosion_5: new Howl({
        src: ["SFX/rocket_explosion_5.mp3"],
        volume: VOLUME,
      }),
      rocket_explosion_6: new Howl({
        src: ["SFX/rocket_explosion_6.mp3"],
        volume: VOLUME,
      }),
      rocket_landing_1: new Howl({
        src: ["SFX/rocket_explosion_6.mp3"],
        volume: VOLUME,
      }),
      rocket_landing_2: new Howl({
        src: ["SFX/rocket_landing_2.mp3"],
        volume: VOLUME,
      }),
      screen_transition_1: new Howl({
        src: ["SFX/screen_transition_1.mp3"],
        volume: VOLUME,
      }),
      screen_transition_2: new Howl({
        src: ["SFX/screen_transition_2.mp3"],
        volume: VOLUME,
      }),
      screen_transition_outro: new Howl({
        src: ["SFX/screen_transition_outro.mp3"],
        volume: VOLUME,
      }),
      scroll_1: new Howl({
        src: ["SFX/scroll_1.mp3"],
        volume: VOLUME,
      }),
      scroll_2: new Howl({
        src: ["SFX/scroll_2.mp3"],
        volume: VOLUME,
      }),
      selectBTN_1: new Howl({
        src: ["SFX/selectBTN_1.mp3"],
        volume: VOLUME,
      }),
      selectBTN_2: new Howl({
        src: ["SFX/selectBTN_2.mp3"],
        volume: VOLUME,
      }),
      set_auto_cashout: new Howl({
        src: ["SFX/set_auto_cashout.mp3"],
        volume: VOLUME,
      }),
      set_auto_click_sound: new Howl({
        src: ["SFX/set_auto_click_sound.mp3"],
        volume: VOLUME,
      }),
      slider: new Howl({
        src: ["SFX/slider.mp3"],
        volume: VOLUME,
      }),
      texting_1: new Howl({
        src: ["SFX/texting_1.mp3"],
        volume: VOLUME,
      }),
      texting_2: new Howl({
        src: ["SFX/texting_2.mp3"],
        volume: VOLUME,
      }),
      you_have_cashed_out_close: new Howl({
        src: ["SFX/you_have_cashed_out_close.mp3"],
        volume: VOLUME,
      }),
    };
  }

  /**
   * Plays the filename from SFX folder without .mp3 extension
   *
   * @param {string} soundName
   */
  play(soundName) {
    if (region == 'retail') return
    if (rocketmanTransport.sound) {
      this.sound_files[soundName].play();
    }
  }

  /**
   * Stops the filename from SFX folder without .mp3 extension
   *
   * @param {string} soundName
   */
  stop(soundName) {
    if (region == 'retail') return
    this.sound_files[soundName].stop();
  }

  /**
   * Sets the volume for given sound
   *
   * @param {string} soundName
   * @param {number} level
   */
  volume(soundName, level) {
    if (region == 'retail') return
    this.sound_files[soundName].volume(level);
  }

  /**
   * Sets the volume for given sound
   *
   * @param {string} soundName
   * @param {boolean} level
   */
  loop(soundName, val) {
    if (region == 'retail') return
    this.sound_files[soundName].loop(val);
  }

  /**
   * Fades sound, from values (0-1 or decimal), to alues (0-1 or decimal), duration miliseconds
   *
   * @param {string} soundName
   * @param {number} from
   * @param {number} to
   * @param {number} duration
   */
  fade(soundName, from, to, duration) {
    if (region == 'retail') return
    this.sound_files[soundName].fade(from, to, duration);
  }
}

const soundFxPlayer = new SoundFxPlayer();
export default soundFxPlayer;
