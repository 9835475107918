import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import seedIcons from "../model/seed-icons";
import ArrowBottomSvg from "../components/svg/arrow-bottom-svg";

const ProvablyFairItem = (props) => {
  const OFFSET = 74;
  return (
    <div>
      <div className="provably-fair-item">
        {props.server ? (
          <img className="pfi-image" src={seedIcons.server} alt="server-seed" />
        ) : props.player ? (
          // <PlayerSeedSvg />
          <img className="pfi-image" src={seedIcons.player} alt="player-seed" />
        ) : null}
        <div className="pfi-title">{props.title}</div>
        <p className="pfi-description">{props.description}</p>
      </div>
      {props.plus ? (
        <div className="pfi-plus">
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={100}
            animateIn="fadeInUp"
          >
            <div className="pfi-line"></div>
          </ScrollAnimation>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={300}
            animateIn="fadeInUp"
          >
            <div className="plus-shape"></div>
          </ScrollAnimation>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={200}
            animateIn="fadeInUp"
          >
            <div className="pfi-line"></div>
          </ScrollAnimation>
        </div>
      ) : props.arrow ? (
        <div className="pfi-arrow">
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={100}
            animateIn="fadeInUp"
          >
            <ArrowBottomSvg />
          </ScrollAnimation>
        </div>
      ) : null}
    </div>
  );
};

export default ProvablyFairItem;
