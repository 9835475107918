import React from "react";
import { Chart } from "react-google-charts";
import { useRocketmanTransport } from "../rocketman/RocketmanTransportContext";
import translate from "../../services/Translation/lang";

export default function MultiplierHistory() {
  const rocketmanContext = useRocketmanTransport();
  const multipliersHistory = rocketmanContext.multipliersHistory;

  const highLight = (mp) => {
    if (Number(mp) > 0.99 && Number(mp) <= 1.99) return "#4472C4";
    if (Number(mp) > 1.99 && Number(mp) <= 4.99) return "#252b63";
    if (Number(mp) > 4.99 && Number(mp) <= 9.99) return "#a7e623";
    if (Number(mp) > 9.99 && Number(mp) <= 49.99) return "#f7fd04";
    if (Number(mp) > 49.99 && Number(mp) <= 99.99) return "#ffbf00";
    if (Number(mp) > 99.99) return "#db1f35";

    return "#252b63";
  };

  const apiDataToChartData = () => {
    return [
      ["", "", { role: "style" }, { role: "annotation" }],
      ...multipliersHistory.map((val) => [
        "",
        val > 10 ? 10 : val,
        highLight(val),
        val > 10 ? Math.floor(val) : null,
      ]),
    ];
  };

  let data = apiDataToChartData();

  const chartOptions = {
    legend: { position: "none" },
    backgroundColor: 'transparent',
    hAxis: {
      textPosition: "none",
    },
    vAxis: {
      // baseline: 0,
      gridlines: {
        color: 'white',
        count: 10
      },
      minorGridlines: {
        count: 0
      }
    },
    chartArea: {
      height: "100%",
      width: "100%",
      top: 48,
      left: 48,
      right: 16,
      bottom: 48,
    },
    theme: "material",
    annotations: {
      textStyle: {
        // fontName: 'Times-Roman',
        fontSize: 24,
        bold: true,
        theme: "material",
        color: "#3BC4F2",
        auraColor: '#070a2a',
        opacity: 0.8
      },
      stem: {
        color: "transparent",
      },
      style: "line",
    },
  };

  return (
    <div className="slide multiplier-history">
      <p className="slider-text-big">{translate('multiplier_history')}</p>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="600px"
        data={data}
        options={chartOptions}
      />
    </div>
  );
}
