import React, { useEffect, useRef } from 'react';
import Odometer from "react-odometerjs";
import soundFxPlayer from '../services/Audio/SoundFxPlayer';
import rocketmanTransport from '../services/RocketmanTransport';
import { useRocketmanTransport } from './rocketman/RocketmanTransportContext';

export default function OdometerComp(props) {

  const rocketmanContext = useRocketmanTransport();
  const multiplier = rocketmanContext.mp;

  useEffect(() => {
    soundFxPlayer.loop('cashout_1',true);
    soundFxPlayer.play('cashout_1');
    return () => soundFxPlayer.stop('cashout_1');
  },[]);

  let value = parseFloat(parseFloat(props.betAmount) * multiplier).toFixed(rocketmanTransport.noOfDecimals);

  const numberOfDecimals = () => {
    if (rocketmanTransport.amountFormat !== "" && !/\./.test(rocketmanTransport.amountFormat)) {
      return "(,ddd)";
    }
    else if (rocketmanTransport.noOfDecimals === 4) {
      return "(,ddd).dddd";
    }
    else return "(,ddd).dd";
  }

  return (
    <Odometer value={value} format={numberOfDecimals()} />
  )
}
