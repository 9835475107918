import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import rocketmanTransport from "../services/RocketmanTransport";
import translate from "../services/Translation/lang";
import CreditRedSvg from "./svg/credit-red-svg";
import Currency from "./Currency/Currency";

const UncreditedWins = (props) => {

  return props.uncreditedWins > 0 ? (
    <div
      data-tip={
        translate("uncredited_wins") + "<br />" + translate("will_be_deposited")
      }
      data-multiline={true}
      className={"header-credit header-credit-uncredited text-white " + (rocketmanTransport.r7css ? "r7css" : "")}
    >
      <CreditRedSvg className="cursor-pointer" onClick={() => rocketmanTransport.requestUserBalanceInAbstract()} />
      {props.uncreditedWins}
      <Currency className={"balance-currency " + (rocketmanTransport.r7css ? "r7css" : "")}>{rocketmanTransport.myCurrency}</Currency>
      <ReactTooltip
        className={"bet-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
        place="bottom"
        effect="solid"
        backgroundColor="#ffbf19"
      />
    </div>
  ) : null
};

export default UncreditedWins;
