import React from "react";
import wonGiffUrls from './assets/won';

export default function Won({submitGif}) {
  return (
    <div className="chat-gifs-container">
      {wonGiffUrls.map((url,i) => {
        return (
          <div className="chat-gifs-category" onClick={() => submitGif(url)} key={i}>
            <img alt="gif-url" src={url} />
          </div>
        )
      })}
    </div>
  );
}
