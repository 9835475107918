import React from "react";
import avatars from "../model/avatars";
import Flag from "../components/Flag/Flag";
import TableShareSvg from "./svg/table-share-svg";
import ProvablyFair from "./svg/provably-fair-svg";
import TableInfoSvg from "./svg/table-info-svg";
import { useHistory } from "react-router-dom";
import translate from "../services/Translation/lang";
import rocketmanTransport from "../services/RocketmanTransport";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { canShare, checkEachCanShare } from "../services/SpamFilter/TicketShareSpamFilter";
import Currency from "./Currency/Currency";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";

const BetWinsItem = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");
  const history = useHistory();
  const { width } = useWindowDimensions();

  const handleBetVerify = (roundId) => {
    if (version !== "desktop" || width < 1024) {
      history.replace({
        search: `?ticketRid=${roundId}&ticketTime=${props.betTime}`,
        pathname: "/bet-verify",
      });
    } else {
      window.setBetVerifyActive(true);
      window.setTicketRid(roundId);
      window.setTicketTime(props.betTime);
      window.setBetInfoActive(false);
    }
  };

  const handleBetInfo = (ticketId) => {
    if (version !== "desktop" || width < 1024) {
      history.replace({
        search: `?ticketId=${ticketId}`,
        pathname: "/bet-info",
      });
    } else {
      window.setBetInfoActive(true, ticketId);
      window.setBetVerifyActive(false);
    }
  };

  const shareTicket = () => {
    if (canShare(props.cashedOut, props.winEur)) {
      const msgParams = {
        avatar: props.avatar,
        countryCode: rocketmanTransport.countryCode,
        username: rocketmanTransport.username,
        entry: translate("good_job_buddy"),
        toUser: props.username,
        cashedOut: props.cashedOut,
        winAmount: props.win.toFixed(rocketmanTransport.noOfDecimals),
        betAmount: props.bet,
        winEur: props.winEur,
        betEur: props.betEur,
        betCurrency: props.betCurrency,

        round: props.roundId,
      };

      const sharedPrefix = "sharedBet-RCF2408-";
      const msg = sharedPrefix + JSON.stringify(msgParams);

      return rocketmanTransport.requestSendChatMessage(msg);
    }
  };

  const highLight = (mp) => {
    if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
    if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
    if (Number(mp) > 99.99) return "win-legendary";

    return "win-small";
  };

  return (
    <div className="top-wins-item-holder">
      <div className="top-wins-header">
        <div className="top-wins-header-left">
          <div className="all-bets-tr-avatar">
            <img src={avatars[props.avatar]} alt="" />
          </div>
          <span className="top-wins-username">
            {props.username}
            {props.isVip ? (
              <img
                className="table-vip-avatar-marker"
                src="/svg/crown.svg"
                alt="crown"
              />
            ) : null}
          </span>
          <div className="all-bets-tr-flag">
            {props.flagCode === "XS" ? (
              <Flag className="xs-flag" code={"RU"} height="10" width="14" />
            ) : (
              <Flag code={props.flagCode} height="10" width="14" />
            )}
          </div>
        </div>
        <div className="top-wins-heaeder-right">
          <button
            className="table-share"
            onClick={() => handleBetInfo(props.ticketId)}
          >
            <TableInfoSvg />
          </button>
          <button
            className="table-share"
            onClick={() => handleBetVerify(props.roundId)}
          >
            <ProvablyFair width={14} height={17} />
          </button>
          {rocketmanTransport.isChatVisible ? <button
            className={["table-share", !props.win || !checkEachCanShare(props.cashedOut,props.winEur) ? "disabled" : ""].join(" ")}
            onClick={() => (props.winAmount && checkEachCanShare(props.cashedOut, props.winEur) ? shareTicket() : () => null)}
          >
            <TableShareSvg />
          </button> : null}
        </div>
      </div>
      <div className="top-wins-separator-line"></div>
      <div
        className={
          "top-wins-footer " + (props.winAmount ? "" : "without-top-wins")
        }
      >
        <div className="mmsb-info">
          <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("cashed_out")}</p>
          <p
            className={["mmsb-info-content", highLight(props.cashedOut)].join(
              " "
            )}
          >
            {props.cashedOut}x
          </p>
        </div>
        {props.winAmount ? (
          <div className="mmsb-info">
            <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("win_amount")}</p>
            <p className="mmsb-info-content">
              {props.winAmount} <Currency>{props.currency}</Currency>
            </p>
          </div>
        ) : null}
        <div className="mmsb-info">
          <p className={"mmsb-info-title mmsb-info-content-round-id-title " + (rocketmanTransport.r7css ? "r7css" : "")}>
            {translate("round_id")}
          </p>
          <p className="mmsb-info-content mmsb-info-content-round-id">
            {props.roundId}
          </p>
        </div>
        <div className="mmsb-info">
          <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("bet_time")}</p>
          <p className="mmsb-info-content">{props.betTime}</p>
        </div>
      </div>
    </div>
  );
};

export default BetWinsItem;
