import * as React from "react";

function PlayNowInactiveSvg(props) {
  return (
    <svg
      width={18}
      height={21}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7v3.5l-4 3V19h4a2 2 0 002 2h2v1h2v-1h2a2 2 0 002-2h4v-5.5l-4-3V7c0-3.156-1.914-7-5-7-3.086 0-5 3.844-5 7zm6 12h2V7c0-2.193-1.398-5-3-5-1.603 0-3 2.807-3 5v12h2v-6h2v6zm-8-4.5L4 13v4H2v-2.5zM14 17v-4l2 1.5V17h-2zm-5-7a2 2 0 110-4 2 2 0 010 4z"
        fill="#fff"
      />
    </svg>
  );
}

export default PlayNowInactiveSvg;
