import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRocketmanTransport } from '../rocketman/RocketmanTransportContext';
import rocketmanTransport from '../../services/RocketmanTransport';

const MIN_CASHOUT_VAL = 1.1;

export default function AutoCashout({advancedAutoPlayShow,advancedAutoPlay, gameState, ticketType, minCashoutMultiplier, setDisabledCashout, setAutoCashValue,currentBet, autoCashValue, buttonIndex, ticketId, autoBet, innerRef, checkMinAutoCashout, cashoutText}) {

  const rocketmanContext = useRocketmanTransport();
  const mp = rocketmanContext.mp;
  const cashedOuts = rocketmanContext.cashedOuts;
  const maxPayout = rocketmanTransport.maxWin;
  const currentMaxWin = mp && Number(mp.toFixed(rocketmanTransport.noOfDecimals)) * Number(currentBet);

  const autoCashoutValMpEqual = Number(autoCashValue) <= mp && Number(mp.toFixed(rocketmanTransport.noOfDecimals));
  const maxCashoutValMpEqual = Number(maxPayout) <= currentMaxWin;
  const onceCashout = useRef(true);
  const onceDisabledButton = useRef(false);
  const [internalDisabled, setInternalDisabled] = useState(false);

  useEffect(() => {
     if (gameState === 'betInProgress') {
      if (autoCashValue && autoCashoutValMpEqual && onceCashout.current && !internalDisabled && minCashoutMultiplier && autoCashValue > 1.01) {
        rocketmanTransport.cashoutBet(autoCashValue, buttonIndex, ticketId, autoBet);
        onceCashout.current = false;
      }
      
      if (autoCashValue && autoCashoutValMpEqual && onceCashout.current && !internalDisabled && !minCashoutMultiplier) {
        rocketmanTransport.cashoutBet(autoCashValue, buttonIndex, ticketId, autoBet);
        onceCashout.current = false;
      } 

      if (currentBet && maxCashoutValMpEqual && onceCashout.current && !internalDisabled) {
        rocketmanTransport.cashoutBet(false, buttonIndex, ticketId, autoBet, Number(maxPayout) / Number(currentBet));
        onceCashout.current = false;
      } 

      if (cashedOuts.length) {
        onceCashout.current = true;
      }
    }

    if (gameState === 'initialState') {
      onceCashout.current = true;
    }
  }, [internalDisabled, gameState,setAutoCashValue, autoCashValue, buttonIndex,autoCashoutValMpEqual,cashedOuts.length, ticketId, autoBet]);

  useEffect(() => {
    if (!onceDisabledButton.current && ticketType == 2 && gameState == 'betInProgress' && mp <= Number(minCashoutMultiplier)) {
      onceDisabledButton.current = true;
      setDisabledCashout(true);
      setInternalDisabled(true);
      if (autoCashValue < minCashoutMultiplier && autoCashValue > 0) {
        setAutoCashValue(minCashoutMultiplier);
      }
    } else if (onceDisabledButton.current && ticketType == 2 && gameState == 'betInProgress' && mp > Number(minCashoutMultiplier)) {
      onceDisabledButton.current = false;
      setDisabledCashout(false);
      setInternalDisabled(false);
    } 

    if (gameState === 'initialState') {
      setDisabledCashout(false);
      setInternalDisabled(false);
      onceDisabledButton.current = false;
    }
  },[ticketType,gameState,minCashoutMultiplier,mp])

  return useMemo(() => (
      <div
        className={
          "auto-cashout-holder " +
          (rocketmanTransport.r7css ? "r7css " : " ") +
          (gameState === "betInProgress " && !advancedAutoPlayShow ? "in-progress " : " ")
          
        }
      >
        <p>{cashoutText}</p>
        <div className='auto-cashout-input-holder'>
        <input
          className={"auto-cashout " + (advancedAutoPlayShow && Number(autoCashValue) < MIN_CASHOUT_VAL ? 'warning' : '')} 
          placeholder={advancedAutoPlayShow && Number(autoCashValue) < MIN_CASHOUT_VAL ? MIN_CASHOUT_VAL : "" }
          value={autoCashValue || ""}
          onChange={(e) => setAutoCashValue(e.target.value) /* gameState === 'betActive' && myBetsByRound.length === 1 ? setAutoCashValue(e.target.value) : gameState !== "betActive" ? setAutoCashValue(e.target.value) : null */}
          onFocus={() => gameState !== "betActive" && autoCashValue}
          onBlur={checkMinAutoCashout}
          disabled={advancedAutoPlay}
          ref={innerRef}
        />
        {!advancedAutoPlay ?<button
          onClick={() => /* gameState !== "betActive" */ setAutoCashValue("")}
          className="cancel-auto-cashout"
          disabled={advancedAutoPlay}
        >
          <div
            className={
              "circle-plus mr-2 " + (autoCashValue ? "opened" : "closed")
            }
          >
            <div className="circle">
              <div className="horizontal"></div>
              <div className="vertical"></div>
            </div>
          </div>
        </button> : null}
        </div>
      </div>
    ),[gameState,autoCashValue,buttonIndex,ticketId, cashoutText,advancedAutoPlay, rocketmanTransport.r7css]);
}
