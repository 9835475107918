import React from 'react'

export default function RetailColUsername(props) {
  return (
    <span className="all-bets-tr-username">
    {props.username}
    {props.isVip && (
      <img
        className="table-vip-avatar-marker"
        src="/svg/crown.svg"
        alt="crown"
      />
    )}
  </span>
  )
}
