import React, { useMemo } from 'react'
import { useRocketmanTransport } from '../rocketman/RocketmanTransportContext';
import Header from './Header';

export default function HeaderLogic(props) {

  const rocketmanContext = useRocketmanTransport();
  const avatarId = rocketmanContext.avatarId;
  const username = rocketmanContext.username;
  const freeBetsCount = rocketmanContext.freeBetsCount;
  const freeBetsMoney = rocketmanContext.freeBetsMoney;
  const width = props.width;
  const promoBalance = rocketmanContext.promoBalance;
  const uncreditedWins = rocketmanContext.uncreditedWins;

  return useMemo(() => <Header uncreditedWins={uncreditedWins} promoBalance={promoBalance} avatarId={avatarId} username={username} freeBetsCount={freeBetsCount} freeBetsMoney={freeBetsMoney} width={width} />,
  [avatarId,username,freeBetsCount, freeBetsMoney,width,promoBalance, uncreditedWins]);
}
